import { Container, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import axios from 'axios';
import Dinero from 'dinero.js';
import moment from 'moment';
import 'moment/locale/en-gb';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import ReportAccountOnlineUsersCountVsAgentsSales from '../../../components/services/sextforce/report/ReportAccountOnlineUsersCountVsAgentsSales';
import ReportAccountOnlineUsersCountVsTotalSales from '../../../components/services/sextforce/report/ReportAccountOnlineUsersCountVsTotalSales';
import ReportAccountTotalsByAgentByDay from '../../../components/services/sextforce/report/ReportAccountTotalsByAgentByDay';
import ReportAccountTotalsByAgentGraph from '../../../components/services/sextforce/report/ReportAccountTotalsByAgentGraph';
import ReportAccountTotalsByAgentShareGraph from '../../../components/services/sextforce/report/ReportAccountTotalsByAgentShareGraph';
import ReportAccountTotalsByProduct from '../../../components/services/sextforce/report/ReportAccountTotalsByProduct';
import ReportAccountTotalsByUser from '../../../components/services/sextforce/report/ReportAccountTotalsByUser';
import ReportAccountTotalsOverview from '../../../components/services/sextforce/report/ReportAccountTotalsOverview';
import ReportSearchBar from '../../../components/services/sextforce/report/ReportSearchBar';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { SextforceReportAccountTotalsByFraction, SextforceReportAccountTotalsByFractionByDay } from '../../../types/transactions';
import { handleHttpError } from '../../../utils/common';

export type TransactionsTotals = {
    totalAmount: Dinero.Dinero;
    totalFee: Dinero.Dinero;
    totalNet: Dinero.Dinero;
    totalVat: Dinero.Dinero;
    totalCommission: Dinero.Dinero;
};

const createEmptyTransactionsTotals = (): TransactionsTotals => {
    return {
        totalAmount: Dinero({ amount: 0, currency: 'USD' }),
        totalFee: Dinero({ amount: 0, currency: 'USD' }),
        totalNet: Dinero({ amount: 0, currency: 'USD' }),
        totalVat: Dinero({ amount: 0, currency: 'USD' }),
        totalCommission: Dinero({ amount: 0, currency: 'USD' }),
    };
};

const SextforceReports = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();
    const isMounted = useRef(true);
    const { data: subscriber } = useSubscriber();

    // Fetching Report Statuses
    const [isFetchingReport, setIsFetchingReport] = useState<boolean>(false);
    const [isFetchingReportAccountTotalsByAgent, setIsFetchingReportAccountTotalsByAgent] = useState<boolean>(false);
    const [isFetchingReportAccountTotalsByAgentByDay, setIsFetchingReportAccountTotalsByAgentByDay] = useState<boolean>(false);
    const [isFetchingReportAccountTotalsByProduct, setIsFetchingReportAccountTotalsByProduct] = useState<boolean>(false);
    const [isFetchingReportAccountTotalsByUser, setIsFetchingReportAccountTotalsByUser] = useState<boolean>(false);
    const [isFetchingReportAccountUsersOnlineCountVsTotalSales, setIsFetchingReportAccountUsersOnlineCountVsTotalSales] =
        useState<boolean>(false);
    const [isFetchingReportAccountUsersOnlineCountVsAgentsSales, setIsFetchingReportAccountUsersOnlineCountVsAgentsSales] =
        useState<boolean>(false);

    // Search Bar
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());
    const [searchMainFreeRange, setSearchMainFreeRange] = useState<boolean>(false);
    const [searchMainWeek, setSearchMainWeek] = useState<number>(moment().week());
    const [searchMainDateFrom, setSearchMainDateFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchMainTimeFrom, setSearchMainTimeFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchMainDateTo, setSearchMainDateTo] = useState<Date>(moment().endOf('week').toDate());
    const [searchMainTimeTo, setSearchMainTimeTo] = useState<Date>(moment().hours(0).minutes(0).toDate());
    const [searchMainLimitTime, setSearchMainLimitTime] = useState<boolean>(false);
    const [searchCompareFreeRange, setSearchCompareFreeRange] = useState<boolean>(false);
    const [searchCompareWeek, setSearchCompareWeek] = useState<number>(moment().week());
    const [searchCompareDateFrom, setSearchCompareDateFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchCompareTimeFrom, setSearchCompareTimeFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchCompareDateTo, setSearchCompareDateTo] = useState<Date>(moment().endOf('week').toDate());
    const [searchCompareTimeTo, setSearchCompareTimeTo] = useState<Date>(moment().endOf('week').toDate());
    const [searchCompareLimitTime, setSearchCompareLimitTime] = useState<boolean>(false);
    const [searchEnableCompare, setSearchEnableCompare] = useState<boolean>(false);

    // Report
    const [reportTimezone, setReportTimezone] = useState<string>(moment.tz.guess());
    const [reportMainIsFreeRange, setReportMainIsFreeRange] = useState<boolean>(false);
    const [reportMainDateFrom, setReportMainDateFrom] = useState<Date | null>(null);
    const [reportMainDateTo, setReportMainDateTo] = useState<Date | null>(null);
    const [reportMainLimitTime, setReportMainLimitTime] = useState<boolean>(false);
    const [reportEnableCompare, setReportEnableCompare] = useState<boolean>(false);
    const [reportCompareIsFreeRange, setReportCompareIsFreeRange] = useState<boolean>(false);
    const [reportCompareDateFrom, setReportCompareDateFrom] = useState<Date | null>(null);
    const [reportCompareDateTo, setReportCompareDateTo] = useState<Date | null>(null);

    // Transactions
    const [transMainByFraction, setTransMainByFraction] = useState<SextforceReportAccountTotalsByFraction[]>([]);
    const [transCompareByFraction, setTransCompareByFraction] = useState<SextforceReportAccountTotalsByFraction[]>([]);
    const [transMainByFractionByDay, setTransMainByFractionByDay] = useState<SextforceReportAccountTotalsByFractionByDay[]>([]);
    const [transCompareByFractionByDay, setTransCompareByFractionByDay] = useState<SextforceReportAccountTotalsByFractionByDay[]>([]);
    const [transMainByProduct, setTransMainByProduct] = useState<any[]>([]);
    const [transCompareByProduct, setTransCompareByProduct] = useState<any[]>([]);
    const [transMainTotals, setTransMainTotals] = useState<TransactionsTotals>(createEmptyTransactionsTotals());
    const [transCompareTotals, setTransCompareTotals] = useState<TransactionsTotals>(createEmptyTransactionsTotals());

    // Top Spenders
    const [topSpendersMain, setTopSpendersMain] = useState<any[] | null>(null);
    const [topSpendersCompare, setTopSpendersCompare] = useState<any[] | null>(null);

    // Online Users Count vs. Total Sales
    const [onlineUsersCountVsTotalSalesMain, setOnlineUsersCountVsTotalSalesMain] = useState<any[] | null>(null);
    const [onlineUsersCountVsTotalSalesCompare, setOnlineUsersCountVsTotalSalesCompare] = useState<any[] | null>(null);

    // Online Users Count vs. Total Sales
    const [onlineUsersCountVsAgentsSalesMain, setOnlineUsersCountVsAgentsSalesMain] = useState<any[] | null>(null);
    const [onlineUsersCountVsAgentsSalesCompare, setOnlineUsersCountVsAgentsSalesCompare] = useState<any[] | null>(null);

    // set isMounted to false when we unmount the component
    useEffect(() => {
        // Retrieve the subscriber
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount && dashboardAccount.timezone) {
            setSearchTimezone(dashboardAccount.timezone);
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    // Reset report when switching subscriber accounts
    useEffect(() => {
        setTransMainByFraction([]);
        setTransCompareByFraction([]);
        setTransMainByFractionByDay([]);
        setTransCompareByFractionByDay([]);
        setTransMainByProduct([]);
        setTransCompareByProduct([]);
        setTransMainTotals(createEmptyTransactionsTotals());
        setTransCompareTotals(createEmptyTransactionsTotals());
        setTopSpendersMain(null);
        setTopSpendersCompare(null);
        setOnlineUsersCountVsTotalSalesMain(null);
        setOnlineUsersCountVsTotalSalesCompare(null);
        setOnlineUsersCountVsAgentsSalesMain(null);
        setOnlineUsersCountVsAgentsSalesCompare(null);

        setReportMainIsFreeRange(false);
        setReportMainDateFrom(null);
        setReportMainDateTo(null);
        setReportMainLimitTime(false);
        setReportEnableCompare(false);
        setReportCompareIsFreeRange(false);
        setReportCompareDateFrom(null);
        setReportCompareDateTo(null);

        setIsFetchingReport(false);
        setIsFetchingReportAccountTotalsByAgent(false);
        setIsFetchingReportAccountTotalsByUser(false);
        setIsFetchingReportAccountUsersOnlineCountVsTotalSales(false);
    }, [subscriber]);

    // Retrieve the sales report from the server
    const requestReport = useCallback(async () => {
        // Fetch Sales By Fraction Report
        const fetchSalesByFractionReport = async (
            startDate: Date,
            endDate: Date,
            timezone: string,
        ): Promise<SextforceReportAccountTotalsByFraction[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.transactions.base}${params.userId}/totalsByFraction?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        // Fetch Sales By Fraction Grouped by Day Report
        const fetchSalesByFractionByDayReport = async (
            startDate: Date,
            endDate: Date,
            timezone: string,
        ): Promise<SextforceReportAccountTotalsByFractionByDay[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.transactions.base}${
                    params.userId
                }/totalsByFractionByDay?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                    timezone,
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        // Fetch Sales By Product Report
        const fetchSalesByProductReport = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.transactions.base}${params.userId}/totalsByProduct?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        // Fetch Top Spenders Report
        const fetchTopSpendersReport = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.transactions.base}${params.userId}/totalsByUser?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        // Fetch online users count vs. total sales report
        const fetchOnlineUsersCountVsTotalSales = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/onlineuserscount?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                    includeSales: 'true',
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        // Fetch online users count vs. Agents sales report
        const fetchOnlineUsersCountVsAgentsSales = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${
                    params.userId
                }/onlineuserscountandagentssales?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                })}`;

                const data = await axios
                    .get(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                return data;
            }

            return [];
        };

        const calculatedMainDateFrom: Date = searchMainLimitTime
            ? moment(searchMainDateFrom).hours(searchMainTimeFrom.getHours()).minutes(searchMainTimeFrom.getMinutes()).toDate()
            : searchMainDateFrom;
        const calculatedMainDateTo: Date = searchMainLimitTime
            ? moment(searchMainDateTo).hours(searchMainTimeTo.getHours()).minutes(searchMainTimeTo.getMinutes()).toDate()
            : searchMainDateTo;

        const calculatedCompareDateFrom: Date = searchCompareLimitTime
            ? moment(searchCompareDateFrom).hours(searchCompareTimeFrom.getHours()).minutes(searchCompareTimeFrom.getMinutes()).toDate()
            : searchCompareDateFrom;
        const calculatedCompareDateTo: Date = searchCompareLimitTime
            ? moment(searchCompareDateTo).hours(searchCompareTimeTo.getHours()).minutes(searchCompareTimeTo.getMinutes()).toDate()
            : searchCompareDateTo;

        const fetchAllReports = async () => {
            setIsFetchingReport(true);
            setIsFetchingReportAccountTotalsByAgent(true);
            setIsFetchingReportAccountTotalsByAgentByDay(true);
            setIsFetchingReportAccountTotalsByProduct(true);
            setIsFetchingReportAccountTotalsByUser(true);
            setIsFetchingReportAccountUsersOnlineCountVsTotalSales(true);
            setIsFetchingReportAccountUsersOnlineCountVsAgentsSales(true);

            // Update report date range
            setReportTimezone(searchTimezone);
            setReportMainIsFreeRange(searchMainFreeRange);
            setReportMainDateFrom(calculatedMainDateFrom);
            setReportMainDateTo(calculatedMainDateTo);
            setReportMainLimitTime(searchMainLimitTime);
            setReportEnableCompare(searchEnableCompare);

            // Retrieve main transactions
            await fetchSalesByFractionReport(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                .then(data => {
                    setTransMainByFraction(data);
                })
                .catch(error => console.error(error));

            if (!searchEnableCompare) {
                setIsFetchingReportAccountTotalsByAgent(false);
            }

            await fetchSalesByFractionByDayReport(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                .then(data => {
                    setTransMainByFractionByDay(data);
                })
                .catch(error => console.error(error));

            if (!searchEnableCompare) {
                setIsFetchingReportAccountTotalsByAgentByDay(false);
            }

            // Retrieve main transactions
            await fetchSalesByProductReport(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                .then(data => {
                    setTransMainByProduct(data);
                })
                .catch(error => console.error(error));

            if (!searchEnableCompare) {
                setIsFetchingReportAccountTotalsByProduct(false);
            }

            // Retrieve top spenders report
            await fetchTopSpendersReport(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                .then(data => setTopSpendersMain(data))
                .catch(error => console.error(error));

            if (!searchEnableCompare) {
                setIsFetchingReportAccountTotalsByUser(false);
            }

            if (!searchMainFreeRange) {
                // Retrieve online users count vs. total sales report
                await fetchOnlineUsersCountVsTotalSales(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                    .then(data => setOnlineUsersCountVsTotalSalesMain(data))
                    .catch(error => console.error(error));

                if (!searchEnableCompare) {
                    setIsFetchingReportAccountUsersOnlineCountVsTotalSales(false);
                }

                // Retrieve online users count vs. Agents sales report
                await fetchOnlineUsersCountVsAgentsSales(calculatedMainDateFrom, calculatedMainDateTo, searchTimezone)
                    .then(data => setOnlineUsersCountVsAgentsSalesMain(data))
                    .catch(error => console.error(error));

                if (!searchEnableCompare) {
                    setIsFetchingReportAccountUsersOnlineCountVsAgentsSales(false);
                }
            } else {
                setOnlineUsersCountVsTotalSalesMain(null);
                setOnlineUsersCountVsAgentsSalesMain(null);
            }

            if (searchEnableCompare) {
                // Update report date range
                setReportCompareIsFreeRange(searchCompareFreeRange);
                setReportCompareDateFrom(calculatedCompareDateFrom);
                setReportCompareDateTo(calculatedCompareDateTo);

                // Retrieve comparisson transactions
                await fetchSalesByFractionReport(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone).then(data => {
                    setTransCompareByFraction(data);
                });

                setIsFetchingReportAccountTotalsByAgent(false);

                await fetchSalesByFractionByDayReport(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone).then(data => {
                    setTransCompareByFractionByDay(data);
                });

                setIsFetchingReportAccountTotalsByAgentByDay(false);

                // Retrieve comparisson transactions
                await fetchSalesByProductReport(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone).then(data => {
                    setTransCompareByProduct(data);
                });

                setIsFetchingReportAccountTotalsByProduct(false);

                // Retrieve the comparisson top spenders report
                await fetchTopSpendersReport(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone)
                    .then(data => setTopSpendersCompare(data))
                    .catch(error => console.error(error));

                setIsFetchingReportAccountTotalsByUser(false);

                if (!searchCompareFreeRange) {
                    // Retrieve the comparisson online users count vs. total sales report
                    await fetchOnlineUsersCountVsTotalSales(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone)
                        .then(data => setOnlineUsersCountVsTotalSalesCompare(data))
                        .catch(error => console.error(error));

                    setIsFetchingReportAccountUsersOnlineCountVsTotalSales(false);

                    // Retrieve the comparisson online users count vs. Agents sales report
                    await fetchOnlineUsersCountVsAgentsSales(calculatedCompareDateFrom, calculatedCompareDateTo, searchTimezone)
                        .then(data => setOnlineUsersCountVsAgentsSalesCompare(data))
                        .catch(error => console.error(error));

                    setIsFetchingReportAccountUsersOnlineCountVsAgentsSales(false);
                } else {
                    setOnlineUsersCountVsAgentsSalesCompare(null);
                }
            }

            setIsFetchingReport(false);
        };

        if (isFetchingReport) {
            return;
        }

        fetchAllReports();

        // only update if we are still mounted
        if (isMounted.current) {
        }
    }, [
        searchMainLimitTime,
        searchMainDateFrom,
        searchMainTimeFrom,
        searchMainDateTo,
        searchMainTimeTo,
        searchCompareLimitTime,
        searchCompareDateFrom,
        searchCompareTimeFrom,
        searchCompareDateTo,
        searchCompareTimeTo,
        isFetchingReport,
        userContext.jwtToken,
        settingsContext.apiKey,
        settingsContext.routes.transactions.base,
        settingsContext.routes.metrics.base,
        params,
        subscriber,
        dialog,
        searchTimezone,
        searchMainFreeRange,
        searchEnableCompare,
        searchCompareFreeRange,
    ]);

    // Tally up main transactions totals
    useEffect(() => {
        let totalAmount: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalFee: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalNet: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalVat: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalCommission: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });

        // Add up the totals
        transMainByFraction &&
            transMainByFraction.forEach(transaction => {
                try {
                    totalAmount = totalAmount.add(Dinero({ amount: transaction.totalAmountCents, currency: 'USD' }));
                    totalFee = totalFee.add(Dinero({ amount: transaction.totalFeeCents, currency: 'USD' }));
                    totalNet = totalNet.add(Dinero({ amount: transaction.totalNetCents, currency: 'USD' }));
                    totalVat = totalVat.add(Dinero({ amount: transaction.totalVatCents, currency: 'USD' }));
                    totalCommission = totalCommission.add(Dinero({ amount: transaction.totalCommissionCents, currency: 'USD' }));
                } catch (error) {
                    console.error(error);
                }
            });

        // Save all the totals state
        const totals: TransactionsTotals = {
            totalAmount,
            totalFee,
            totalNet,
            totalVat,
            totalCommission,
        };

        setTransMainTotals(totals);
    }, [transMainByFraction]);

    // Tally up comparission transactions totals
    useEffect(() => {
        let totalAmount: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalFee: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalNet: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalVat: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });
        let totalCommission: Dinero.Dinero = Dinero({ amount: 0, currency: 'USD' });

        // Add up the totals
        transCompareByFraction.forEach(transaction => {
            try {
                totalAmount = totalAmount.add(Dinero({ amount: transaction.totalAmountCents, currency: 'USD' }));
                totalFee = totalFee.add(Dinero({ amount: transaction.totalFeeCents, currency: 'USD' }));
                totalNet = totalNet.add(Dinero({ amount: transaction.totalNetCents, currency: 'USD' }));
                totalVat = totalVat.add(Dinero({ amount: transaction.totalVatCents, currency: 'USD' }));
                totalCommission = totalCommission.add(Dinero({ amount: transaction.totalCommissionCents, currency: 'USD' }));

                // // Add up this agent's commission
                // if (transaction.agent) {
                //     const commission = Dinero({ amount: transaction.totalNetCents, currency: 'USD' }).percentage(
                //         transaction.commissionPercentOverride ? transaction.commissionPercentOverride : transaction.agent.commissionPercent,
                //     );

                //     totalCommission = totalCommission.add(commission);
                // }
            } catch (error) {
                console.error(error);
            }
        });

        // Save all the totals state
        const totals: TransactionsTotals = {
            totalAmount,
            totalFee,
            totalNet,
            totalVat,
            totalCommission,
        };

        setTransCompareTotals(totals);
    }, [transCompareByFraction]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0' }}>
            {/* subscriber && '_id' in subscriber && subscriber._id && (
                <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                    <CardHeader
                        avatar={<Avatar>{subscriber.username.charAt(0).toUpperCase()}</Avatar>}
                        title={subscriber.username}
                        subheader={subscriber._id}
                    />
                </Card>
            ) */}

            <Typography variant="h5" sx={{ paddingBottom: theme.spacing(4) }}>
                <BackNavigationButton
                    {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                />{' '}
                Sales Report for {subscriber?.username}
            </Typography>

            <ReportSearchBar
                timezone={searchTimezone}
                setTimezone={setSearchTimezone}
                mainSearch={{
                    freeRange: searchMainFreeRange,
                    setFreeRange: setSearchMainFreeRange,
                    week: searchMainWeek,
                    setWeek: setSearchMainWeek,
                    dateFrom: searchMainDateFrom,
                    setDateFrom: setSearchMainDateFrom,
                    timeFrom: searchMainTimeFrom,
                    setTimeFrom: setSearchMainTimeFrom,
                    dateTo: searchMainDateTo,
                    setDateTo: setSearchMainDateTo,
                    timeTo: searchMainTimeTo,
                    setTimeTo: setSearchMainTimeTo,
                    limitTime: searchMainLimitTime,
                    setLimitTime: setSearchMainLimitTime,
                }}
                compareSearch={{
                    freeRange: searchCompareFreeRange,
                    setFreeRange: setSearchCompareFreeRange,
                    week: searchCompareWeek,
                    setWeek: setSearchCompareWeek,
                    dateFrom: searchCompareDateFrom,
                    setDateFrom: setSearchCompareDateFrom,
                    timeFrom: searchCompareTimeFrom,
                    setTimeFrom: setSearchCompareTimeFrom,
                    dateTo: searchCompareDateTo,
                    setDateTo: setSearchCompareDateTo,
                    timeTo: searchCompareTimeTo,
                    setTimeTo: setSearchCompareTimeTo,
                    limitTime: searchCompareLimitTime,
                    setLimitTime: setSearchCompareLimitTime,
                }}
                enableCompare={searchEnableCompare}
                setEnableCompare={setSearchEnableCompare}
                isFetchingReport={isFetchingReport}
                requestReport={requestReport}
            />

            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2), textAlign: 'center' }}>
                {reportMainIsFreeRange || !reportMainDateFrom ? '' : `Results for Week ${moment(reportMainDateFrom).week()} `}
                {!reportEnableCompare || reportCompareIsFreeRange || !reportCompareDateFrom
                    ? ''
                    : ` vs. Week ${moment(reportCompareDateFrom).week()} `}
                {reportMainDateFrom && reportMainDateTo ? (
                    <>
                        Results for
                        <br />
                        {reportMainDateFrom.toLocaleDateString()} {reportMainDateFrom.toLocaleTimeString(undefined, { hour12: true })} -{' '}
                        {reportMainDateTo.toLocaleDateString()} {reportMainDateTo.toLocaleTimeString(undefined, { hour12: true })}
                    </>
                ) : (
                    'No Dates Selected'
                )}
                {reportEnableCompare && reportCompareDateFrom && reportCompareDateTo && (
                    <>
                        <br />
                        vs.
                        <br />
                        {reportCompareDateFrom.toLocaleDateString()}
                        {reportCompareDateFrom.toLocaleTimeString(undefined, {
                            hour12: true,
                        })}{' '}
                        - {reportCompareDateTo.toLocaleDateString()}
                        {reportCompareDateTo.toLocaleTimeString(undefined, {
                            hour12: true,
                        })}
                    </>
                )}
                {reportMainDateFrom && (
                    <>
                        <br />({reportTimezone})
                    </>
                )}
            </Typography>

            <ReportAccountTotalsOverview
                mainTotals={transMainTotals}
                compareTotals={transCompareTotals}
                compare={reportEnableCompare}
                isFetching={isFetchingReportAccountTotalsByAgent}
            />

            <ReportAccountTotalsByProduct
                mainData={transMainByProduct}
                compareData={transCompareByProduct}
                compare={reportEnableCompare}
                isFetching={isFetchingReportAccountTotalsByProduct}
                theme={theme}
            />

            {/* <ReportAccountTotalsByAgent
                subscriberId={params.userId || ''}
                searchParams={`${new URLSearchParams({
                    freeRange: reportMainIsFreeRange ? 'true' : 'false',
                    week: searchMainWeek.toString(),
                    dateFrom: reportMainDateFrom ? reportMainDateFrom.toISOString() : '',
                    dateTo: reportMainDateTo ? reportMainDateTo.toISOString() : '',
                    limitTime: reportMainLimitTime ? 'true' : 'false',
                    timezone: reportTimezone,
                })}`}
                transactionsMain={transMainByFraction}
                transactionsCompare={transCompareByFraction}
                compare={reportEnableCompare}
                isFetching={isFetchingReportAccountTotalsByAgent}
            /> */}

            <ReportAccountTotalsByAgentByDay
                subscriberId={params.userId || ''}
                searchParams={`${new URLSearchParams({
                    freeRange: reportMainIsFreeRange ? 'true' : 'false',
                    week: searchMainWeek.toString(),
                    dateFrom: reportMainDateFrom ? reportMainDateFrom.toISOString() : '',
                    dateTo: reportMainDateTo ? reportMainDateTo.toISOString() : '',
                    limitTime: reportMainLimitTime ? 'true' : 'false',
                    timezone: reportTimezone,
                })}`}
                transactionsMain={transMainByFraction}
                transactionsCompare={transCompareByFraction}
                transactionsByDayMain={transMainByFractionByDay}
                transactionsByDayCompare={transCompareByFractionByDay}
                compare={reportEnableCompare}
                isFetchingTotalsByFraction={isFetchingReportAccountTotalsByAgent}
                isFetchingTotalsByFractionByDay={isFetchingReportAccountTotalsByAgentByDay}
            />

            <ReportAccountTotalsByAgentGraph
                transactionsMain={transMainByFraction}
                transactionsCompare={transCompareByFraction}
                compare={reportEnableCompare}
                isFetching={isFetchingReportAccountTotalsByAgent}
            />

            <ReportAccountTotalsByAgentShareGraph
                transactionsMain={transMainByFraction}
                transactionsCompare={transCompareByFraction}
                compare={reportEnableCompare}
                isFetching={isFetchingReportAccountTotalsByAgent}
            />

            <ReportAccountTotalsByUser
                topSpendersMain={topSpendersMain}
                compare={reportEnableCompare}
                topSpendersCompare={topSpendersCompare}
                isFetching={isFetchingReportAccountTotalsByUser}
            />

            <ReportAccountOnlineUsersCountVsTotalSales
                onlineUsersCountMain={onlineUsersCountVsTotalSalesMain}
                onlineUsersCountCompare={onlineUsersCountVsTotalSalesCompare}
                compare={reportEnableCompare}
                reportMainIsFreeRange={reportMainIsFreeRange}
                reportCompareIsFreeRange={reportCompareIsFreeRange}
                isFetching={isFetchingReportAccountUsersOnlineCountVsTotalSales}
            />

            <ReportAccountOnlineUsersCountVsAgentsSales
                onlineUsersCountMain={onlineUsersCountVsAgentsSalesMain}
                onlineUsersCountCompare={onlineUsersCountVsAgentsSalesCompare}
                compare={reportEnableCompare}
                agents={
                    transMainByFraction
                        ? transMainByFraction.map(item => {
                              return { fraction: item.fraction, agent: item.agent };
                          })
                        : []
                }
                reportMainIsFreeRange={reportMainIsFreeRange}
                reportCompareIsFreeRange={reportCompareIsFreeRange}
                isFetching={isFetchingReportAccountUsersOnlineCountVsAgentsSales || isFetchingReportAccountTotalsByAgent}
            />
        </Container>
    );
};

export default SextforceReports;
