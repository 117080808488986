import InfoIcon from '@mui/icons-material/Info';
import { Card, CardContent, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import moment from 'moment-timezone';
import useMessageUsersCurrentSessions from '../../../../hooks/useMessageUsersCurrentSessions';
import useSubscriber from '../../../../hooks/useSubscriber';
import MessageUsersUnsendCurrentSession from '../unsendMessage/MessageUsersUnsendCurrentSession';
import MessageUsersCurrentSession from './MessageUsersCurrentSession';

const MessageUsersOverviewStatus = () => {
    const timezone: string = moment.tz.guess();

    const { data: subscriber, isLoading: isSubscriberLoading } = useSubscriber();

    const {
        data: currentSessions,
        isLoading: isCurrentSessionLoading,
        isMessageUsersSendSessionRunning,
        isMessageUsersUnendSessionRunning,
        isMessageUsersSendJobInQueue,
        isMessageUsersUnsendJobInQueue,
    } = useMessageUsersCurrentSessions();

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: 4, marginBottom: 2 }}>
                Status
            </Typography>

            {isCurrentSessionLoading || isSubscriberLoading ? (
                <Skeleton />
            ) : (
                <>
                    {isMessageUsersSendSessionRunning || isMessageUsersSendJobInQueue ? (
                        <MessageUsersCurrentSession />
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Message Users Last Run{' '}
                                            <Tooltip title={`Time Zone: ${timezone}`}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Typography>

                                        {subscriber.lastMessageUsersDate ? (
                                            <>{moment(subscriber.lastMessageUsersDate).tz(timezone).format('L hh:mm a')} </>
                                        ) : (
                                            'Message Users has not run yet on this account'
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}

                    {isMessageUsersUnendSessionRunning || isMessageUsersUnsendJobInQueue ? (
                        <MessageUsersUnsendCurrentSession
                            currentSession={currentSessions.unsendMessage.sessions[0]}
                            isSessionRunning={isMessageUsersUnendSessionRunning}
                            isJobRunning={isMessageUsersUnsendJobInQueue}
                        />
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: 2 }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Unsend Message Last Run{' '}
                                            <Tooltip title={`Time Zone: ${timezone}`}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Typography>

                                        {subscriber.lastUnsendMessageDate ? (
                                            <>{moment(subscriber.lastUnsendMessageDate).tz(timezone).format('L hh:mm a')} </>
                                        ) : (
                                            'Unsend Message has not run yet on this account'
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default MessageUsersOverviewStatus;
