import InfoIcon from '@mui/icons-material/Info';
import { Alert, Backdrop, Card, CardContent, CircularProgress, Container, Grid, Tooltip, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { getServiceStatusName, handleHttpError, handleHttpErrorResponse } from '../../../utils/common';
// import MessageUsersHistoryGraph from '../../../components/services/messageUsers/history/MessageUsersHistoryGraph';
import { useQuery } from 'react-query';
import MessageUsersHistorySearchBar from '../../../components/services/messageUsers/history/MessageUsersHistorySearchBar';
import MessageUsersHistorySessionsList from '../../../components/services/messageUsers/history/MessageUsersHistorySessionsList';
import MessageUsersUnsendCurrentSession from '../../../components/services/messageUsers/unsendMessage/MessageUsersUnsendCurrentSession';

const MessageUsersHistory = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();
    const isMounted = useRef(true);
    const timezone: string = moment.tz.guess();

    const [subscriber, setSubscriber] = useState<any | null>(null);
    const [subscriberLoading, setSubscriberLoading] = useState<boolean>(false);
    const [subscriberLoaded, setSubscriberLoaded] = useState<boolean>(false);
    const [refreshCurrentSession, setRefreshCurrentSession] = useState<boolean>(false);
    const [isUnsendingMessage, setIsUnsendingMessage] = useState<boolean>(false);

    // Search Bar
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());
    const [searchMainDateFrom, setSearchMainDateFrom] = useState<Date>(moment().toDate());

    // Report
    const [isFetchingReport, setIsFetchingReport] = useState<boolean>(false);
    const [reportTimezone, setReportTimezone] = useState<string>(moment.tz.guess());
    const [reportMainDateFrom, setReportMainDateFrom] = useState<Date | null>(null);

    // Result
    const [mainData, setMainData] = useState<any[]>([]);
    const [sextforceActive, setSextforceActive] = useState<boolean>(false);
    const [hoursSinceMessage, setHoursSinceMessage] = useState<number>(6);
    const [showSalesMode, setShowSalesMode] = useState<string>('net');
    const [sessionEarningsData, setSessionEarningsData] = useState<any[]>([]);
    const [sessionEarningsDataLoading, setSessionEarningsDataLoading] = useState<boolean>(false);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const fetchSubscriber = async () => {
                const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

                setSubscriberLoading(true);

                await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        setSubscriber(data);
                        setSextforceActive(data && getServiceStatusName(data, 'sextforce', false) === 'Active');

                        if (!data || !('_id' in data) || !data._id) {
                            navigate('/');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setSubscriberLoading(false);
                setSubscriberLoaded(true);
            };

            fetchSubscriber();
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    // Fetch Report
    // Retrieve the sales report from the server
    const requestReport = useCallback(async () => {
        // Fetch Sales By Fraction Report
        const fetchFollowBackReport = async (date: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/history?${new URLSearchParams({
                    startDate: moment(date).tz(timezone, true).startOf('day').toISOString(),
                    endDate: moment(date).tz(timezone, true).endOf('day').toISOString(),
                })}`;

                setIsFetchingReport(true);

                const result = await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        return data;
                    })
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });

                setIsFetchingReport(false);

                return result;
            }

            return [];
        };

        const fetchAllReports = async () => {
            // Update report date range
            setReportTimezone(searchTimezone);
            setReportMainDateFrom(searchMainDateFrom);

            // Fetch Follow-BackReport
            const result = await fetchFollowBackReport(searchMainDateFrom, searchTimezone);

            setSessionEarningsData([]);
            setMainData(result);
        };

        if (isFetchingReport) {
            return;
        }

        fetchAllReports();

        // only update if we are still mounted
        if (isMounted.current) {
        }
    }, [
        dialog,
        isFetchingReport,
        params,
        searchMainDateFrom,
        searchTimezone,
        settingsContext.apiKey,
        settingsContext.routes.messageUsers.base,
        subscriber,
        userContext.jwtToken,
    ]);

    // Auto load current week's report
    useEffect(() => {
        if (subscriberLoaded) {
            requestReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriberLoaded]);

    // Fetch Follow-Back Overview
    const fetchCurrentSession = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/currentSessions`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: currentSessions,
        isLoading: currentSessionLoading,
        refetch: refetchCurrentSession,
    } = useQuery(
        ['messageUsersCurrentSessions'],
        () => {
            if (subscriber) {
                return fetchCurrentSession();
            }
        },
        {
            onSuccess: (data: any) => {
                const isSessionRunning: boolean = data && data.unsendMessage.sessions.length > 0;
                const isJobInQueue: boolean = data && data.unsendMessage.queue;

                setRefreshCurrentSession(isSessionRunning || isJobInQueue ? true : false);
            },
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber ? true : false,
            refetchInterval: refreshCurrentSession ? 15000 : 0,
        },
    );

    const handleUnsendMessage = (sessionId: string) => {
        const unsendMessage = async (sessionId: string) => {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/unsendMessage`;

            setIsUnsendingMessage(true);

            const unsendSession = await fetch(query, {
                method: 'post',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sessionId }),
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(async error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            if (unsendSession) {
                refetchCurrentSession();
            }

            setIsUnsendingMessage(false);
        };

        unsendMessage(sessionId);
    };

    // Fetch the sales data of a messageUsers session
    // const fetchSessionSalesData = async (id: string): Promise<any> => {
    //     if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
    //         const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/sales/${id}?${new URLSearchParams({
    //             hoursSinceMessage: hoursSinceMessage.toString(),
    //         })}`;

    //         return fetch(query, {
    //             method: 'get',
    //             headers: {
    //                 Authorization: userContext.jwtToken,
    //                 apiKey: settingsContext.apiKey,
    //             },
    //         })
    //             .then(async response => {
    //                 if (response.ok) {
    //                     return response.json();
    //                 } else {
    //                     handleHttpErrorResponse(response, dialog);
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error(error);
    //                 handleHttpError(error, dialog);
    //             });
    //     }
    // };

    useEffect(() => {
        const fetchSessionSalesData = async (id: string): Promise<any> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/sales/${id}?${new URLSearchParams({
                    hoursSinceMessage: hoursSinceMessage.toString(),
                })}`;

                return fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });
            }
        };

        if (!subscriberLoading && subscriber && mainData && sextforceActive) {
            // Fetch session sales data using fetchSessionSalesData for each mainData record but only fetch one record at a time
            const fetchSessionSalesDataOneByOne = async () => {
                setSessionEarningsDataLoading(true);

                for (const sessionData of mainData) {
                    const sessionSalesData = await fetchSessionSalesData(sessionData._id);

                    if (sessionSalesData) {
                        setSessionEarningsData(prevState => [...prevState, sessionSalesData]);
                    }
                }

                setSessionEarningsDataLoading(false);
            };

            fetchSessionSalesDataOneByOne();
        }
    }, [
        dialog,
        hoursSinceMessage,
        mainData,
        params,
        settingsContext.apiKey,
        settingsContext.routes.messageUsers.base,
        sextforceActive,
        subscriber,
        subscriberLoading,
        userContext.jwtToken,
    ]);

    // const sessionsSalesData = useQueries(
    //     mainData.map(sessionData => {
    //         return {
    //             queryKey: ['sessionSalesData', sessionData._id, mainData, hoursSinceMessage],
    //             queryFn: () => fetchSessionSalesData(sessionData._id),
    //             enabled: subscriber && sextforceActive,
    //             refetchOnWindowFocus: false,
    //         };
    //     }),
    // );

    // const sessionsSalesDataLoading = sessionsSalesData.some(sessionQuery => sessionQuery.isLoading || sessionQuery.isFetching);
    const isUnsendMessageSessionRunning: boolean = currentSessions && currentSessions.unsendMessage.sessions.length > 0 ? true : false;
    const isUnsendMessageJobInQueue: boolean = currentSessions && currentSessions.unsendMessage.queue ? true : false;

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Message Users History for {subscriber.username}
                    </Typography>

                    {subscriber ? (
                        <>
                            <MessageUsersHistorySearchBar
                                timezone={searchTimezone}
                                setTimezone={setSearchTimezone}
                                date={searchMainDateFrom}
                                setDate={setSearchMainDateFrom}
                                isFetchingReport={isFetchingReport}
                                requestReport={requestReport}
                            />

                            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2), textAlign: 'center' }}>
                                {reportMainDateFrom ? `Results for ${reportMainDateFrom.toLocaleDateString()}` : 'No Dates Selected'}
                                {reportMainDateFrom && ` (${reportTimezone})`}
                            </Typography>

                            {isUnsendMessageSessionRunning || isUnsendMessageJobInQueue ? (
                                <MessageUsersUnsendCurrentSession
                                    currentSession={currentSessions.unsendMessage.sessions[0]}
                                    isSessionRunning={isUnsendMessageSessionRunning}
                                    isJobRunning={isUnsendMessageJobInQueue}
                                />
                            ) : (
                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    Unsend Message Last Run{' '}
                                                    <Tooltip title={`Time Zone: ${timezone}`}>
                                                        <InfoIcon fontSize="small" />
                                                    </Tooltip>
                                                </Typography>

                                                {subscriber.lastUnsendMessageDate ? (
                                                    <>{moment(subscriber.lastUnsendMessageDate).tz(timezone).format('L hh:mm a')} </>
                                                ) : (
                                                    'Unsend Message has not run yet on this account'
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}

                            <MessageUsersHistorySessionsList
                                subscriber={subscriber}
                                data={mainData}
                                currentUnsendMessageSessions={currentSessions ? currentSessions.unsendMessage : null}
                                isLoadingCurrentUnsendMessageSessions={subscriberLoading || currentSessionLoading}
                                isFetching={isFetchingReport}
                                timezone={reportTimezone}
                                handleUnsendMessage={handleUnsendMessage}
                                sextforceActive={sextforceActive}
                                showSalesMode={showSalesMode}
                                setShowSalesMode={setShowSalesMode}
                                hoursSinceMessage={hoursSinceMessage}
                                setHoursSinceMessage={setHoursSinceMessage}
                                // sessionsSalesData={sessionsSalesData}
                                // sessionsSalesDataLoading={sessionsSalesDataLoading}
                                sessionsSalesData={sessionEarningsData}
                                sessionsSalesDataLoading={subscriberLoading || sessionEarningsDataLoading}
                            />
                        </>
                    ) : (
                        <>
                            <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                                You do not have Follow-Back setup on this account. Please contact Admin for more information.
                            </Alert>
                        </>
                    )}
                </>
            )}

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isUnsendingMessage}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default MessageUsersHistory;
