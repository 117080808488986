import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Alert,
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Theme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import useSubscriberVaultAlbums from '../../../../hooks/useSubscriberVaultAlbums';
import { OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import MessageUsersFormAddMediasRemoveSelected from './MessageUsersFormAddMediasRemoveSelected';
import MessageUsersFormAddMediasSelector, { VaultMediaResponseList } from './MessageUsersFormAddMediasSelector';

type Props = {
    serviceName: string;
    includeMedia: boolean;
    setIncludeMedia: (state: boolean) => void;
    vaultAlbum: number;
    setVaultAlbum: (albumId: number) => void;
    vaultAlbumName: string | undefined;
    setVaultAlbumName: (albumName: string | undefined) => void;
    existingMedias?: OnlyFansVaultMedias.RestructuredResponseItem[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    thumbnails: any[];
    getThumbnail: (thumbId: number, thumbUrl: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;
    session?: any;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    disabled?: boolean;
};

const MessageUsersFormAddMedias = (props: Props) => {
    const {
        serviceName,
        includeMedia,
        setIncludeMedia,
        vaultAlbum,
        setVaultAlbum,
        vaultAlbumName,
        setVaultAlbumName,
        existingMedias,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        thumbnails,
        getThumbnail,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        session,
        disabled,
    } = props;
    const theme: Theme = useTheme();

    const {
        data: vaultAlbums,
        refetch: refetchAlbums,
        isLoading: vaultAlbumsLoading,
        isFetching: vaultAlbumsFetching,
    } = useSubscriberVaultAlbums(includeMedia);

    const [sortAlbums, setSortAlbums] = useState<boolean>(true);
    const [sortedAlbums, setSortedAlbums] = useState<any[] | undefined>([]);
    const [albumMedias, setAlbumMedias] = useState<VaultMediaResponseList[]>([]);
    const [albumHasMore, setAlbumHasMore] = useState<boolean>(false);

    useEffect(() => {
        if (vaultAlbums) {
            if (sortAlbums) {
                // Copy the array to avoid mutating the original array
                const vaultAlbumsCopy = [...vaultAlbums];
                // Sort albums alphabetically
                setSortedAlbums(vaultAlbumsCopy.sort((a: any, b: any) => a.name.localeCompare(b.name)));
            } else {
                setSortedAlbums(vaultAlbums);
            }
        } else {
            setSortedAlbums([]);
        }
    }, [sortAlbums, vaultAlbums]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="medium"
                                                checked={includeMedia}
                                                onChange={(e, checked) => {
                                                    setIncludeMedia(checked);
                                                }}
                                                disabled={disabled}
                                            />
                                        }
                                        label={<Typography variant="h6">Add Media?</Typography>}
                                    />
                                </Grid>
                                {includeMedia && !vaultAlbumsLoading && !vaultAlbumsFetching && (
                                    <>
                                        <Grid item xs={'auto'}>
                                            <Tooltip title="Sort albums alphabetically or custom like on OnlyFans">
                                                <IconButton
                                                    onClick={() => {
                                                        setSortAlbums(!sortAlbums);
                                                    }}
                                                    disabled={disabled}
                                                >
                                                    <SortByAlphaIcon
                                                        fontSize="large"
                                                        color={disabled ? 'disabled' : sortAlbums ? 'primary' : 'secondary'}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Tooltip title="Reload Albums from OnlyFans">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => {
                                                        refetchAlbums();
                                                    }}
                                                    disabled={disabled}
                                                >
                                                    <RefreshIcon fontSize="large" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {session && session.payload && session.payload.mediaFiles && session.payload.mediaFiles.length > 0 && (
                                <Grid container spacing={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                                    <Grid item xs={12}>
                                        <Alert severity="warning" variant="filled">
                                            Previous session's photo/video will not be included with this message. Please select the
                                            photo/video again or select a different photo/video.
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}

                            {(includeMedia || vaultAlbumsFetching) && (
                                <Grid
                                    container
                                    spacing={1}
                                    flexGrow={0}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                >
                                    {vaultAlbumsLoading || vaultAlbumsFetching ? (
                                        <Grid item xs>
                                            <Box textAlign={'center'}>
                                                <Typography variant="caption">
                                                    Retrieving your Vault Albums...
                                                    <br />
                                                    <br />
                                                    This might take a while depending on how many albums you have and OnlyFans servers speed
                                                    🤷‍♂️
                                                    <br />
                                                    <br />
                                                    <CircularProgress size={24} />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={12} flexGrow={0}>
                                                <>
                                                    {vaultAlbums ? (
                                                        <FormControl fullWidth disabled={disabled}>
                                                            <InputLabel id="vaultAlbum">Vault Album</InputLabel>
                                                            <Select
                                                                labelId="vaultAlbum"
                                                                defaultValue={vaultAlbums.length > 0 && vaultAlbums[0].id}
                                                                value={vaultAlbum}
                                                                onChange={(e: SelectChangeEvent<any>) => {
                                                                    if (vaultAlbum !== e.target.value) {
                                                                        setVaultAlbum(e.target.value);
                                                                        e.target.value !== -1 &&
                                                                            vaultAlbum &&
                                                                            setVaultAlbumName(
                                                                                e.target.value === 0
                                                                                    ? 'All'
                                                                                    : vaultAlbums.find(
                                                                                          (item: any) => item.id === e.target.value,
                                                                                      ).name,
                                                                            );
                                                                        setAlbumMedias([]);
                                                                        setAlbumHasMore(false);
                                                                    }
                                                                }}
                                                                fullWidth
                                                                size="medium"
                                                                label="Vault Album"
                                                                error={vaultAlbum === -1}
                                                            >
                                                                <MenuItem value={-1} sx={{ color: theme.palette.grey[800] }}>
                                                                    {vaultAlbumsLoading || vaultAlbumsFetching
                                                                        ? 'Loading...'
                                                                        : 'Select Album'}
                                                                </MenuItem>
                                                                {!vaultAlbumsLoading && !vaultAlbumsFetching && (
                                                                    <MenuItem value={0}>
                                                                        <strong>All</strong>
                                                                    </MenuItem>
                                                                )}
                                                                {!vaultAlbumsLoading &&
                                                                    !vaultAlbumsFetching &&
                                                                    sortedAlbums &&
                                                                    sortedAlbums.map((list: any) => (
                                                                        <MenuItem value={list.id} key={list.id}>
                                                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                {list.name}
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : (
                                                        <Alert severity="error">Could not load lists!</Alert>
                                                    )}
                                                </>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            )}

                            {includeMedia && !vaultAlbumsLoading && !vaultAlbumsFetching && vaultAlbum !== -1 && vaultAlbumName && (
                                <MessageUsersFormAddMediasSelector
                                    serviceName={serviceName}
                                    albumId={vaultAlbum}
                                    albumName={vaultAlbumName}
                                    medias={medias}
                                    setMedias={setMedias}
                                    mediasPreviews={mediasPreviews}
                                    setMediasPreviews={setMediasPreviews}
                                    albumMedias={albumMedias}
                                    setAlbumMedias={setAlbumMedias}
                                    albumHasMore={albumHasMore}
                                    setAlbumHasMore={setAlbumHasMore}
                                    thumbnails={thumbnails}
                                    getThumbnail={getThumbnail}
                                    getAudio={getAudio}
                                    tagCreators={tagCreators}
                                    setTagCreators={setTagCreators}
                                    taggedCreators={taggedCreators}
                                    setTaggedCreators={setTaggedCreators}
                                    taggedReleaseForms={taggedReleaseForms}
                                    setTaggedReleaseForms={setTaggedReleaseForms}
                                    theme={theme}
                                    disabled={disabled}
                                />
                            )}

                            {medias.length > 0 && (
                                <MessageUsersFormAddMediasRemoveSelected
                                    thumbnails={thumbnails}
                                    medias={medias}
                                    setMedias={setMedias}
                                    mediasPreviews={mediasPreviews}
                                    setMediasPreviews={setMediasPreviews}
                                    theme={theme}
                                    disabled={disabled}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormAddMedias;
