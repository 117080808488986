import Joi from 'joi';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import {
    OnlyFansSubscriberHostessAutoReplyExpired,
    OnlyFansSubscriberHostessAutoReplyFreeloaders,
} from '../types/hostessAutoReplyFreeloaders';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../types/messageUsersAutoSendWelcomeMessage';
import { OnlyFansLists } from '../types/onlyFansTypes';
import { convertNewlinesToParagraphs, handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export type OnlyFansSubscriptionsUserType =
    | 'all'
    | 'fansAll'
    | 'fansActive'
    | 'fansExpired'
    | 'followingActive'
    | 'followingExpired'
    | 'onlineActive'
    | 'onlineFollowing'
    | 'onlineAll'
    | 'fansRestricted'
    | 'fansBlocked'
    | 'fansRecent'
    | 'lists'
    | 'posts'
    | 'messages'
    | 'metrics';

export interface S3Media {
    url: string;
    bucket: string;
    eTag: string;
    key: string;
    mimeType: string;
    size: number;
    dateCreated: Date;
}

/** DigitalCriminal Dynamic Rules */
export interface DynamicRules {
    // eslint-disable-next-line camelcase
    static_param: string;
    format: string;
    // eslint-disable-next-line camelcase
    checksum_indexes: number[];
    // eslint-disable-next-line camelcase
    checksum_constants: number[];
    // eslint-disable-next-line camelcase
    checksum_constant: number;
    // eslint-disable-next-line camelcase
    app_token: string;
    // eslint-disable-next-line camelcase
    remove_headers: string[];
    // eslint-disable-next-line camelcase
    error_code: number;
    message: string;
}

/*
 * Describes the HTTP Header of an OnlyFans user
 */
export interface OnlyFansSubscriberHeader {
    'User-Agent': string;
    Cookie: string;
    'x-bc': string;
}

export interface OnlyFansSubscriberGeneral {
    hasLeftReview?: boolean;
    doNotContact?: boolean;
}

export interface OnlyFansSubscriberFinancial {
    billingName?: string;
    billingAddress?: string;
    billingCity?: string;
    billingCounty?: string;
    billingPostcode?: string;
    billingCountry?: string;
    billingCompanyName?: string;
    billingVatNumber?: string;
    sharedCredit?: object;
    insufficientCreditAlertSent?: boolean;
}

export interface OnlyFansSubscriberUnitCost {
    unitCost?: object;
}

/**
 * AWS Cognito
 */
export interface Cognito {
    username: string;
    category?: string;
    myIp?: string | null;
    avatar?: S3Media;
}

/**
 * Describes the action to be takes to process a user
 */
export type OnlyFansUserActionType = 'follow' | 'unfollow' | 'ignore' | 'block' | 'unblock' | 'unrestrict' | 'none';

export interface OnlyFanyUsersFilterOptions {
    // eslint-disable-next-line camelcase
    total_spent?: number;
    tips?: number;
    duration?: number;
    inactive?: number;
    recommenders?: boolean;
    online?: boolean;
    promoId?: number;
    thresholdMonths?: number;
    thresholdMinTotalSpent?: number;
}

export interface OnlyFansFollowBack {
    active: boolean;
    paidDate?: Date;
    followBackLimit?: number;
    followBackFilter?: OnlyFanyUsersFilterOptions;
    ignoreCreators?: boolean;
    ignoreType?: string;
    ignoreSafeList?: string[];
    blockSafeList?: string[];
    followFollowingExpired?: boolean;
    followFansExpired?: boolean;
    followFansActive?: boolean;
    freeFollowingExpired?: boolean;
    sortDirection?: 'desc' | 'asc';
    followBack3Finished?: boolean;
    lastFollowBackDate?: Date;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsDelayBeforeSendingMessage {
    fromSeconds: number;
    toSeconds: number;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator {
    id: number;
    name: string;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm {
    id: number;
    name: string;
}

export interface MomentTimePeriod {
    amount: number;
    unit: string;
}

export const MomentTimePeriodSchema = Joi.object<MomentTimePeriod>({
    amount: Joi.number().required(),
    unit: Joi.string().required(),
});

export interface OnlyFansListIdAndName {
    id: number | OnlyFansLists.Type;
    name: string;
}

export interface OnlyFansSubscriberMessageUsers {
    messageOnlineUsers?: OnlyFansSubscriberUnitCost;
    messageUsers?: OnlyFansSubscriberUnitCost;
    autoMessageOnlineUsers?: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    autoMessageNewSubscriber?: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    autoMessageReturningSubscriber?: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    autoMessageNewTrialSubscriber?: OnlyFansSubscriberMessageUsersAutoMessageDetails;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HousekeepingListCriteriaConditionRange {
    lt?: number | Date;
    lte?: number | Date;
    gt?: number | Date;
    gte?: number | Date;
    within?: null;
    amount?: number;
    unit?: moment.unitOfTime.DurationConstructor;
}

export interface HousekeepingListCriteriaIncludes {
    // eslint-disable-next-line no-use-before-define
    [key: string]: HousekeepingListCriteriaCondition;
}

export interface HousekeepingListCriteriaCondition {
    eq?: any;
    ne?: any;
    fraction?: any;
    count?: any;
    contains?: string;
    startsWith?: string;
    range?: HousekeepingListCriteriaConditionRange;
    includes?: HousekeepingListCriteriaIncludes[];
    excludes?: HousekeepingListCriteriaIncludes[];
}

export interface HousekeepingListCriteria {
    subscribedOnData?: any;
    // $or?: HousekeepingListCriteria;
    [key: string]: HousekeepingListCriteriaCondition | HousekeepingListCriteria | undefined;
}

export interface HousekeepingListExportResults {
    googleSpreadsheetId: string;
    googleSpreadheetName: string;
}

export interface HousekeepingList {
    listName: string;
    listId?: number | string;
    addPrefixToUserDisplayName?: boolean;
    userDisplayNamePrefix?: string;
    exportResults?: HousekeepingListExportResults;
    criteria: HousekeepingListCriteria | HousekeepingListCriteria[];
}

export interface OnlyFansSubscriberHousekeeping {
    active: boolean;
    paidDateFirstRun?: Date;
    paidDate?: Date;
    userActionType?: OnlyFansUserActionType;
    canReceiveMessages?: boolean;
    thresholdMonths?: number;
    thresholdMinTotalSpent?: number;
    addPrefixToUserDisplayName?: boolean;
    userDisplayNamePrefix?: string;
    lists?: HousekeepingList[];
}

export interface OnlyFansSubscriberUnblockUsers {
    active: boolean;
    paidDate?: Date;
    usersType?: OnlyFansSubscriptionsUserType;
    followUsers?: boolean;
    thresholdMinTotalSpent?: number;
    skipCreators?: boolean;
    onlyCreators?: boolean;
}

export interface OnlyFansSubscriberUnrestrictUsers {
    active: boolean;
    paidDate?: Date;
}

export interface OnlyFansSubscriberUnfollowUsers {
    active: boolean;
    paidDate?: Date;
    target: OnlyFansSubscriptionsUserType;
    targetListName?: string;
    unfollowUsersLimit?: number;
}

export interface OnlyFansSubscriberBlockUsers {
    active: boolean;
    paidDate?: Date;
    targetListName: string;
    blockUsersLimit?: number;
    unblock?: boolean;
}

export interface OnlyFansSubscriberBlockKnownAssholes {
    active: boolean;
    paidDate?: Date;
    blockUsersLimit?: number;
}

export interface OnlyFansSubscriberDeletePosts {
    active: boolean;
    paidDate?: Date;
    action?: string;
    fromDate?: Date;
    toDate?: Date;
    minLikes?: number;
}

export interface OnlyFansSubscriberRenameUsers {
    active: boolean;
    paidDate?: Date;
    resetAllNames?: boolean;
}

export interface OnlyFansSubscriberScheduledPosts {
    active: boolean;
    paidDate?: Date;
    sourceVaultAlbum: string;
    postImages: boolean;
    postVideos: boolean;
}

export interface OnlyFansSubscriberInCommonFans {
    active: boolean;
    paidDate?: Date;
    secondAccount: string;
    listName: string;
    listId?: number;
    secondAccountListId?: number;
    removeExistingFans?: boolean;
    removedListName?: string;
    removedListId?: number;
    secondRemovedListId?: number;
}

export interface OnlyFansSextforceAutoRepost {
    active: boolean;
}

export interface OnlyFansSextforceAutoMassDm {
    active: boolean;
    lastSextforceAutoMassDmCollectorDate?: Date;
}

export interface OnlyFansSextforceAutoCampaignReply {
    active: boolean;
    delayBeforeSendingMessage: boolean;
}

export const OnlyFansSextforceAutoCampaignReplySchema = Joi.object<OnlyFansSextforceAutoCampaignReply>({
    active: Joi.boolean().required(),
    delayBeforeSendingMessage: Joi.boolean().required().default(false),
});

export interface OnlyFansSextforceMetrics {
    ignoreTrialIds?: number[];
}

export interface OnlyFansSextforceBigBrother {
    active: boolean;
    restrictedWords: string[];
    restrictedWordsWhiteList: string[];
    restrictedWordsFoundCount: number;
    restrictedWordsDeletedCount: number;
}

export interface OnlyFansSextforceLiveStreamAddTippersToList {
    active: boolean;
    addTippersToListCount?: number;
    addTippersToIndividualList?: boolean;
    addTippersToAllTippersList?: boolean;
    allTippersListId?: number;
}

export interface OnlyFansSextforceLiveStream {
    addTippersToList?: OnlyFansSextforceLiveStreamAddTippersToList;
}

export const arrayOnlyFansSextforceAutoRandomPostType = ['random', 'newest'];

export type OnlyFansSextforceAutoPostType = typeof arrayOnlyFansSextforceAutoRandomPostType[number];

export interface OnlyFansSextfoceAutoRandomPost {
    active: boolean;
    type: OnlyFansSextforceAutoPostType;
    sourceAlbumId?: number;
    randomPostsCount: number;
    postCaption: boolean;
    postImage: boolean;
    postVideo: boolean;
    postAudio: boolean;
    postGif: boolean;
    postEveryAmount: number;
    postEveryUnit: string;
    postExpiriesAfter?: number;
    nextPostDate?: Date;
    lastAutoRandomPostDate?: Date;
}

export interface OnlyFansSextforce {
    active: boolean;
    paidDate?: Date;
    metrics?: OnlyFansSextforceMetrics;
    autoRepost?: OnlyFansSextforceAutoRepost;
    autoMassDm?: OnlyFansSextforceAutoMassDm;
    autoCampaignReply?: OnlyFansSextforceAutoCampaignReply;
    bigBrother?: OnlyFansSextforceBigBrother;
    liveStream?: OnlyFansSextforceLiveStream;
    autoRandomPost?: OnlyFansSextfoceAutoRandomPost;
    lastProcessedNotificationId?: number;
    lastScrapeSubscribersDate?: Date;
}

export interface OnlyFansSubscriberHostessAutoReply {
    active: boolean;
    freeloaders?: OnlyFansSubscriberHostessAutoReplyFreeloaders;
}

export interface OnlyFansSubscriberHostess {
    active: boolean;
    paidDate?: Date;
    autoReply?: OnlyFansSubscriberHostessAutoReply;
}

export namespace OnlyFansEarnings {
    export interface OnlyFansEarningsStats {
        list: List;
    }

    export interface List {
        months: { [key: string]: Month };
        total: Total;
    }

    export interface Month {
        subscribes: ChatMessage[];
        total_net: number;
        total_gross: number;
        chat_messages?: ChatMessage[];
        tips?: ChatMessage[];
    }

    export interface ChatMessage {
        time: number;
        net: number;
        gross: number;
    }

    export interface Total {
        chat_messages: All;
        all: All;
        subscribes: All;
        tips: All;
        ref: All;
    }

    export interface All {
        total_net: number;
        total_gross: number;
    }
}

/**
 * Describes an OnlyFans user database record
 */
export interface OnlyFansSubscriber {
    _id?: string;
    username: string;
    cognito?: Cognito;
    email: string;
    password: string;
    loginMethod?: string;
    contactEmail?: string;
    telegramChatId: string;
    realName?: string;
    paidDate?: Date;
    paymentMethod?: string;
    financial?: OnlyFansSubscriberFinancial;
    notes?: string;
    header: OnlyFansSubscriberHeader;
    ofUserId?: number;
    isLoggedIn?: boolean;
    general?: OnlyFansSubscriberGeneral;
    onlyFansEarnings?: OnlyFansEarnings.Total;
    onlyFansPerformerTop?: number;
    followBack?: OnlyFansFollowBack;
    lastFollowBackDate?: Date;
    messageUsers?: OnlyFansSubscriberMessageUsers;
    lastFindRestrictingUsersDate?: Date;
    housekeeping?: OnlyFansSubscriberHousekeeping;
    lastHousekeepingDate?: Date;
    unblockUsers?: OnlyFansSubscriberUnblockUsers;
    lastUnblockUsersDate?: Date;
    unrestrictUsers?: OnlyFansSubscriberUnrestrictUsers;
    lastUnrestrictUsersDate?: Date;
    unfollowUsers?: OnlyFansSubscriberUnfollowUsers;
    lastUnfollowUsersDate?: Date;
    blockUsers?: OnlyFansSubscriberBlockUsers;
    lastBlockUsersDate?: Date;
    deletePosts?: OnlyFansSubscriberDeletePosts;
    lastDeletePostsDate?: Date;
    renameUsers?: OnlyFansSubscriberRenameUsers;
    scheduledPosts?: OnlyFansSubscriberScheduledPosts;
    lastSendUserPromoDate?: Date;
    blockKnownAssholes?: OnlyFansSubscriberBlockKnownAssholes;
    lastBlockKnownAssholesDate?: Date;
    sextforce?: OnlyFansSextforce;
    lastScrapeTransactionsDate?: Date;
    lastScrapeNotificationsDate?: Date;
    inCommonFans?: OnlyFansSubscriberInCommonFans;
    lastInCommonFansDate?: Date;
    hostess?: OnlyFansSubscriberHostess;
    lastMessageOnlineUsersDate?: Date;
    lastMessageUsersDate?: Date;
    lastUnsendMessageDate?: Date;
    lastSextforceMetricsDate?: Date;
    lastSextforceMetricsDailyStatsDate?: Date;
    lastSextforceAutoRepostDate?: Date;
}

const useSubscriber = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    const [setServiceRunningLoading, setSetServiceRunningLoading] = useState<boolean>(false);
    const [setServiceSettingsHostessAutoReplyFreeloadersLoading, setSetServiceSettingsHostessAutoReplyFreeloadersLoading] =
        useState<boolean>(false);
    const [setServiceSettingsHostessAutoReplyExpiredLoading, setSetServiceSettingsHostessAutoReplyExpiredLoading] =
        useState<boolean>(false);
    const [setSubscriberCategoryLoading, setSetSubscriberCategoryLoading] = useState<boolean>(false);
    const [moveCreditToAnotherAccountLoading, setMoveCreditToAnotherAccountLoading] = useState<boolean>(false);

    // Fetch Follow-Back Overview
    const fetchsubscriber = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

            return axios
                .get(query)
                .then(async response => response.data)
                .catch(error => {
                    if (error.status === 401 && error.message === 'jwt expired') {
                        // Refresh window to get new JWT Token
                        userContext.refreshToken();
                    } else {
                        console.error(error);
                        handleHttpError(error, dialog);
                    }
                });
        }
    };

    const subscriber = useQuery(
        ['subscriber', params.userId, userContext.jwtToken],
        () => {
            return fetchsubscriber();
        },
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 1000 * 60 * 5,
            enabled: userContext.jwtToken && params.userId ? true : false,
        },
    );

    const subscriberLogout = async (subscriberId?: string): Promise<{ success: boolean }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${subscriberId || params.userId}/logout`;

            return axios
                .post(query)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        } else {
            return { success: false };
        }
    };

    const setServiceRunning = async (service: string, running: boolean): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/${service}/running`;

            setSetServiceRunningLoading(true);

            return axios
                .put(query, {
                    running,
                })
                .then(async response => {
                    subscriber.refetch();

                    setSetServiceRunningLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSetServiceRunningLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const setServiceSettings = async (service: string, settings: any): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/${service}/settings`;

            setSetServiceRunningLoading(true);

            return axios
                .put(query, { settings })
                .then(async response => {
                    if (response.status !== 200) {
                        throw new Error(response.statusText);
                    }

                    subscriber.refetch();

                    setSetServiceRunningLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSetServiceRunningLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const setServiceSettingsHostessAutoReplyFreeloaders = async (settings: OnlyFansSubscriberHostessAutoReplyFreeloaders): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/hostess.autoReply.freeloaders/settings`;

            setSetServiceSettingsHostessAutoReplyFreeloadersLoading(true);

            return axios
                .put(query, {
                    settings: {
                        ...settings,
                        replies: settings.replies.map(reply => {
                            return convertNewlinesToParagraphs(reply);
                        }),
                    },
                })
                .then(async response => {
                    subscriber.refetch();

                    setSetServiceSettingsHostessAutoReplyFreeloadersLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSetServiceSettingsHostessAutoReplyFreeloadersLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const setServiceSettingsHostessAutoReplyExpired = async (settings: OnlyFansSubscriberHostessAutoReplyExpired): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/hostess.autoReply.expired/settings`;

            setSetServiceSettingsHostessAutoReplyExpiredLoading(true);

            return axios
                .put(query, {
                    settings: {
                        ...settings,
                        replies: settings.replies.map(reply => {
                            return convertNewlinesToParagraphs(reply);
                        }),
                    },
                })
                .then(async response => {
                    if (response.status !== 200) {
                        throw new Error(response.data);
                    }

                    subscriber.refetch();

                    setSetServiceSettingsHostessAutoReplyExpiredLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSetServiceSettingsHostessAutoReplyExpiredLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const setSubscriberCategory = async (userId: string, categoryId: string | null): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/category/${categoryId || ''}`;

            setSetSubscriberCategoryLoading(true);

            return axios(query, {
                method: categoryId ? 'PUT' : 'DELETE',
            })
                .then(async response => {
                    setSetSubscriberCategoryLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSetSubscriberCategoryLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const moveCreditToAnotherAccount = async (userId: string, targetUserId: string, amount: number): Promise<{ success: boolean }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username && userId && targetUserId && amount > 0) {
            const query: string = `${settingsContext.routes.subscribers.base}${userId}/credit/move`;

            setMoveCreditToAnotherAccountLoading(true);

            return axios
                .put(query, {
                    targetSubscriberId: targetUserId,
                    amount,
                })
                .then(response => {
                    setMoveCreditToAnotherAccountLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);

                    setMoveCreditToAnotherAccountLoading(false);

                    handleHttpError(error, dialog);

                    return { success: false };
                });
        } else {
            return { success: false };
        }
    };

    return {
        ...subscriber,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettings,
        setServiceSettingsHostessAutoReplyFreeloaders,
        setServiceSettingsHostessAutoReplyFreeloadersLoading,
        setServiceSettingsHostessAutoReplyExpired,
        setServiceSettingsHostessAutoReplyExpiredLoading,
        setSubscriberCategory,
        setSubscriberCategoryLoading,
        moveCreditToAnotherAccount,
        moveCreditToAnotherAccountLoading,
        subscriberLogout,
    };
};

export default useSubscriber;
