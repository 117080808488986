import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import { Button, Card, CardContent, Grid, IconButton, Skeleton, Stack, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import useDashboardAccount from '../../../../hooks/useDashboardAccount';
import useSextforceMetricsGroups, { OnlyFansMetricsGroupWithSummary } from '../../../../hooks/useSextforceMetricsGroups';
import { d2f, metricTypeName } from '../../../../utils/common';
import SextforceMetricsGroupDialog from './SextforceMetricsGroupDialog';
import SextforceMetricsGroupsTable from './SextforceMetricsGroupsTable';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: OnlyFansMetricsGroupWithSummary[], amountType: 'gross' | 'net', timezone: string) => {
    const headers = [
        {
            label: 'Username',
            value: 'username',
        },
        {
            label: 'ID',
            value: 'foreignId',
        },
        {
            label: 'Type',
            value: 'type',
        },
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: `Created At (${timezone})`,
            value: 'createdAt',
        },
        {
            label: 'OnlyFans Name',
            value: 'name',
        },
        {
            label: 'Platform',
            value: 'platform',
        },
        {
            label: '@ Username',
            value: 'associatedUsername',
        },
        {
            label: 'Subscribers Total',
            value: 'subscribersCount',
        },
        {
            label: 'Claims Total',
            value: 'claimsCount',
        },
        {
            label: `Earnings ${amountType === 'gross' ? 'Gross' : 'Net'} Total (USD)`,
            value: 'earningsTotal',
        },
    ];

    const data = rows.map((group: OnlyFansMetricsGroupWithSummary) => {
        const metrics: {
            username: string;
            foreignId: number | string | undefined;
            type: string;
            active: string | boolean;
            createdAt: string;
            name: string;
            platform: string;
            associatedUsername: string;
            subscribersCount: number | string | undefined;
            claimsCount: number | string | undefined;
            earningsTotal: number | string;
        }[] = group.metrics.map(metric => {
            const earningsTotal =
                metric.payload && metric.payload.earningsTotal && typeof metric.payload.earningsTotal === 'object'
                    ? d2f(metric.payload.earningsTotal) * (amountType === 'gross' ? 1 : 0.8)
                    : 0;
            const claimsCount = (metric.payload && metric.payload.counters && metric.payload.counters.claimsCount) || undefined;
            const subscribersCount = (metric.payload && metric.payload.counters && metric.payload.counters.countSubscribers) || undefined;

            return {
                username: metric.subscriber.username,
                foreignId: metric.foreignId,
                type: metricTypeName(metric.type),
                active: metric.payload.active || false,
                createdAt: (metric.payload && moment(metric.payload.createdAt).tz(timezone).format('L hh:mm a')) || '',
                name: (metric.payload && metric.payload.name) || '',
                platform: (metric.settings && metric.settings.platform) || '',
                associatedUsername: metric.associatedUsername || '',
                subscribersCount,
                claimsCount,
                earningsTotal,
            };
        });

        const earningsTotal =
            group.earningsTotal && typeof group.earningsTotal === 'object'
                ? d2f(group.earningsTotal) * (amountType === 'gross' ? 1 : 0.8)
                : 0;

        // Insert a row for border with separators in each member
        metrics.push({
            username: '-----',
            foreignId: '-----',
            type: '-----',
            active: '-----',
            createdAt: '-----',
            name: '-----',
            platform: '-----',
            associatedUsername: '-----',
            subscribersCount: '-----',
            claimsCount: '-----',
            earningsTotal: '-----',
        });

        // Insert a row for the group summary
        metrics.push({
            username: '',
            foreignId: undefined,
            type: '',
            active: '',
            createdAt: group.createdAt ? moment(group.createdAt).tz(timezone).format('L hh:mm a') : '',
            name: group.name,
            platform: '',
            associatedUsername: '',
            subscribersCount: group.subscribersTotal !== 0 ? group.subscribersTotal : undefined,
            claimsCount: group.claimsTotal !== 0 ? group.claimsTotal : undefined,
            earningsTotal,
        });

        return metrics;
    });

    return { headers, data };
};

type Props = {
    subscriber: any;
    metricType: 'trial' | 'campaign';
    amountType: 'gross' | 'net';
};

const SextforceMetricsGroups = (props: Props) => {
    const { subscriber, metricType, amountType } = props;
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());

    // Set global MomentJS locale
    moment.locale('en-gb');

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount) {
            if (dashboardAccount && dashboardAccount.timezone) {
                setTimezone(dashboardAccount.timezone);
            }
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    const { data: groups, isLoading: isGroupsLoading, metricsGroupDelete } = useSextforceMetricsGroups(metricType);

    const [groupDialogOpen, setGroupDialogOpen] = useState<boolean>(false);
    const [metricsGroup, setMetricsGroup] = useState<OnlyFansMetricsGroupWithSummary | null>(null);

    const openGroupDialog = (metricId: string | null) => {
        if (metricId && !groups) {
            return;
        }

        setGroupDialogOpen(true);

        if (groups && metricId) {
            setMetricsGroup(groups.find(group => group._id === metricId) || null);
        }
    };

    const handleGroupDialogClose = () => {
        setGroupDialogOpen(false);
        setMetricsGroup(null);
    };

    const handleDeleteGroup = (groupId: string) => {
        dialog
            .confirm({ title: 'Delete Group', message: 'Are you sure you want to delete this group?' })
            .then(() => {
                metricsGroupDelete(groupId);
            })
            .catch(() => {});
    };

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        if (!groups) {
            return;
        }

        const transformedData = transformTransactionsForExport(groups, amountType, timezone);

        const stackedData: {
            username: string;
            foreignId: number | string | undefined;
            type: string;
            active: string | boolean;
            createdAt: string;
            name: string;
            platform: string;
            associatedUsername: string;
            subscribersCount: number | string | undefined;
            claimsCount: number | string | undefined;
            earningsTotal: number | string;
        }[] = [];

        transformedData.data.forEach(group => {
            group.forEach(metric => {
                stackedData.push(metric);
            });

            // Insert a spacer row
            stackedData.push({
                username: '',
                foreignId: '',
                type: '',
                active: '',
                createdAt: '',
                name: '',
                platform: '',
                associatedUsername: '',
                subscribersCount: '',
                claimsCount: '',
                earningsTotal: '',
            });
        });

        // Format JSON data to CSV
        const csv = parseCsv(stackedData, {
            fields: transformedData.headers,
            delimiter: ',',
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `groups_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        if (!groups) {
            return;
        }

        const transformedData = transformTransactionsForExport(groups, amountType, timezone);

        const workbook = XLSX.utils.book_new();

        transformedData.data.forEach((group, index) => {
            const worksheet = XLSX.utils.aoa_to_sheet([]);

            XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map(header => header.label)]);

            XLSX.utils.sheet_add_json(worksheet, group, {
                origin: 'A2',
                skipHeader: true,
                header: transformedData.headers.map((header: any) => header.value),
            });

            XLSX.utils.book_append_sheet(
                workbook,
                worksheet,
                `${index} - ${(group[group.length - 1].name as string).replace(/[^a-z0-9]/gi, '_').substring(0, 25)}`,
            );
        });

        // Create a descriptive filename
        const filename: string = `groups_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xlsx`;

        XLSX.writeFile(workbook, filename, { bookType: 'xlsx', bookSST: false, cellStyles: true });
    };

    return (
        <Card sx={{ width: '100%', marginBottom: 1 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent={'space-between'}>
                            <Typography variant="h6">Grouped Metrics</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Tooltip title="Download as CSV">
                                    <span>
                                        <IconButton disabled={isGroupsLoading || !groups} onClick={async () => handleDownloadReportCsv()}>
                                            <FontAwesomeIcon
                                                icon={faFileCsv}
                                                size="2x"
                                                color={isGroupsLoading ? theme.palette.text.disabled : theme.palette.info.main}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Download as Excel">
                                    <span>
                                        <IconButton disabled={isGroupsLoading || !groups} onClick={() => handleDownloadReportExcel()}>
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                size="2x"
                                                color={isGroupsLoading ? theme.palette.text.disabled : theme.palette.info.main}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Button
                                    onClick={() => openGroupDialog('')}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<JoinFullIcon />}
                                >
                                    Create
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        Group metrics from different pages in your dashboard to see the combined earnings. You can group tracking links
                        (campaigns) and trials together from the same and multiple pages as necessary.
                    </Grid>

                    {isGroupsLoading ? (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            {groups && groups.length > 0 ? (
                                <SextforceMetricsGroupsTable
                                    groups={groups}
                                    amountType={amountType}
                                    openGroupDialog={openGroupDialog}
                                    handleDeleteGroup={handleDeleteGroup}
                                    theme={theme}
                                />
                            ) : (
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: 50 }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        No groups found
                                    </Typography>
                                </Stack>
                            )}
                        </Grid>
                    )}
                </Grid>

                <SextforceMetricsGroupDialog
                    open={groupDialogOpen}
                    onClose={handleGroupDialogClose}
                    subscriber={subscriber}
                    metricType={metricType}
                    metricsGroup={metricsGroup}
                />
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsGroups;
