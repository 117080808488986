import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, CircularProgress, Grid, Theme, Typography, useTheme } from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMessageUsersCurrentSessions from '../../../../hooks/useMessageUsersCurrentSessions';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse, objectIdToDate, stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersCurrentSessionExclude from './MessageUsersCurrentSessionExclude';

const refreshBackgroundStyle = { animation: 'backgroundRefresh .5s ease forwards' };

const MessageUsersCurrentSession = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const dialog = useDialog();
    const timezone: string = moment.tz.guess();
    const [stoppingSession, setStoppingSession] = useState<boolean>(false);

    const {
        data: currentSessions,
        isLoading: currentSessionLoading,
        refetch: refetchCurrentSession,
        isMessageUsersSendSessionRunning,
        isMessageUsersSendJobInQueue,
    } = useMessageUsersCurrentSessions();

    const currentSession = currentSessions?.messageUsers.sessions[0];

    // Fetch Follow-Back Overview
    const handleStopCurrentSession = () => {
        const stopCurrentSession = async (): Promise<any> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && currentSession) {
                const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/session/${currentSession._id}`;

                setStoppingSession(true);

                await fetch(query, {
                    method: 'delete',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setStoppingSession(false);
            }
        };

        stopCurrentSession();
    };

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(2),
            }}
        >
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Session {isMessageUsersSendSessionRunning ? 'Currently Running' : 'Waiting to Start'} <CircularProgress size={14} />
                </Typography>
                {isMessageUsersSendJobInQueue && !isMessageUsersSendSessionRunning && (
                    <Grid container spacing={1}>
                        <Grid item xs>
                            Please wait for this session to finish before sending another message
                        </Grid>
                    </Grid>
                )}
                {isMessageUsersSendSessionRunning && currentSession && (
                    <Grid container alignItems={'flex-start'} spacing={1} sx={{ maxWidth: theme.breakpoints.values.sm }}>
                        <Grid item xs={12}>
                            <strong>Message</strong>
                        </Grid>
                        <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                            {currentSession.payload.message
                                ? stripHtml(currentSession.payload.message)
                                      .split('\n')
                                      .map((i: string, key: number) => {
                                          return <div key={key}>{i}</div>;
                                      })
                                : ''}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>%USERNAME% Replacement</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {currentSession.payload.usernameReplacement || <div style={{ color: theme.palette.grey[500] }}>None</div>}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Message Price</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {currentSession.payload.lockMessagePrice
                                ? dinero({ amount: Math.trunc(currentSession.payload.lockMessagePrice * 100) }).toFormat()
                                : 'Free'}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Locked Message Text?</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {currentSession.payload.lockedText ? 'Yes' : 'No'}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Users Group</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {targetUsersName(currentSession.targetUsers, currentSession.filter)}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Exclude</strong>
                        </Grid>
                        <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                            <MessageUsersCurrentSessionExclude exclude={currentSession.payload.exclude} theme={theme} />
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Auto Unsend</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {currentSession.payload.autoUnsendTimerPeriod && currentSession.payload.autoUnsendTimerPeriodAmount ? (
                                `${currentSession.payload.autoUnsendTimerPeriodAmount} ${currentSession.payload.autoUnsendTimerPeriod} after session finished`
                            ) : (
                                <div style={{ color: theme.palette.grey[500] }}>None</div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Delay Between Messages</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {currentSession.payload.delayBetweenMessages ? (
                                `${currentSession.payload.delayBetweenMessages / 1000} seconds`
                            ) : (
                                <div style={{ color: theme.palette.grey[500] }}>None</div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Started At</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {moment(objectIdToDate(currentSession._id)).tz(timezone).format('L LT')}
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Latest Update</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {moment(currentSession.lastStatusUpdate).tz(timezone).format('L LT')}
                            </span>
                        </Grid>
                        {currentSession.targetUsers === 'lists' && (
                            <>
                                <Grid item xs={4}>
                                    <strong>List Name</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                        {currentSession.payload.listName}
                                    </span>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={6}>
                            <strong>Latest Status</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {currentSession.status}
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Messages Sent So Far</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                {currentSession.followBackSessionSummary ? currentSession.followBackSessionSummary.totalMessagesSent : 0}
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                color="error"
                                loading={stoppingSession}
                                onClick={() => {
                                    dialog
                                        .confirm({
                                            message: 'Are you sure you want to stop this session?',
                                            title: 'Stop Message Users',
                                            ok: { text: 'STOP', variant: 'contained', color: 'error' },
                                            cancel: { text: 'Cancel' },
                                        })
                                        .then(() => {
                                            handleStopCurrentSession();
                                        })
                                        .catch(() => {});
                                }}
                            >
                                Stop Session!
                            </LoadingButton>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default MessageUsersCurrentSession;
