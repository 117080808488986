import { Grid } from '@mui/material';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/Header';
import { UserContext } from '../store/UserContext';
import SharedCreditCheckout from './account/SharedCreditCheckout';
import AdminSubscriberEdit from './admin/AdminSubscriberEdit';
import AdminSubscribers from './admin/AdminSubscribers';
import DashboardHome from './DashboardHome';
import LabsOverview from './labs/LabsOverview';
import PerformerRanking from './labs/performerRanking/PerformerRanking';
import FollowBackOverview from './services/followBack/FollowBackOverview';
import FollowBackReport from './services/followBack/FollowBackReport';
import FollowBackSettings from './services/followBack/FollowBackSettings';
import FollowBackWelcome from './services/followBack/FollowBackWelcome';
import HostessAutoReplyToExpired from './services/hostess/HostessAutoReplyToExpired';
import HostessAutoReplyToFreeloaders from './services/hostess/HostessAutoReplyToFreeloaders';
import HostessOverview from './services/hostess/HostessOverview';
import HostessWelcome from './services/hostess/HostessWelcome';
import MessageUsersAutoSendWelcomeMessage from './services/messageUsers/MessageUsersAutoSendWelcomeMessage';
import MessageUsersAutoSendWelcomeMessageNewSubscriber from './services/messageUsers/MessageUsersAutoSendWelcomeMessageNewSubscriber';
import MessageUsersAutoSendWelcomeMessageNewTrialSubscriber from './services/messageUsers/MessageUsersAutoSendWelcomeMessageNewTrialSubscriber';
import MessageUsersAutoSendWelcomeMessageReturningSubscriber from './services/messageUsers/MessageUsersAutoSendWelcomeMessageReturningSubscriber';
import MessageUsersHistory from './services/messageUsers/MessageUsersHistory';
import MessageUsersOverview from './services/messageUsers/MessageUsersOverview';
import MessageUsersSendMessage from './services/messageUsers/MessageUsersSendMessage';
import MessageUsersWelcome from './services/messageUsers/MessageUsersWelcome';
import SextforceAssignTransactions from './services/sextforce/SextforceAssignTransactions';
import SextforceAutoCampaignTipReply from './services/sextforce/SextforceAutoCampaignTipReply';
import SextforceAutoRepost from './services/sextforce/SextforceAutoRepost';
import SextforceBigBrotherRestrictedWords from './services/sextforce/SextforceBigBrotherRestrictedWords';
import SextforceLiveStreamAddTippersToList from './services/sextforce/SextforceLiveStramAddTippersToList';
import SextforceManageAgents from './services/sextforce/SextforceManageAgents';
import SextforceManageSaleProofs from './services/sextforce/SextforceManageSaleProofs';
import SextforceMetrics from './services/sextforce/SextforceMetrics';
import SextforceMetricsAccount from './services/sextforce/SextforceMetricsAccount';
import SextforceMetricsCampaigns from './services/sextforce/SextforceMetricsCampaigns';
import SextforceMetricsTrials from './services/sextforce/SextforceMetricsTrials';
import SextforceOverview from './services/sextforce/SextforceOverview';
import SextforceReports from './services/sextforce/SextforceReports';
import SextforceTransactions from './services/sextforce/SextforceTransactions';
import SextforceWelcome from './services/sextforce/SextforceWelcome';
import SubscriberOverview from './subscriber/SubscriberOverview';

const Authenticated = () => {
    const userContext = useContext(UserContext);

    return (
        <>
            <Grid container spacing={0} style={{ height: '100vh' }}>
                <Grid item xs={12} style={{ height: '64px' }}>
                    <Header />
                </Grid>
                <Grid container spacing={0} style={{ height: '100%' }}>
                    <Grid item xs={12}>
                        <Routes>
                            <Route path="subscribers/:userId/account/sharedCreditCheckout" element={<SharedCreditCheckout />} />

                            <Route path="subscribers/:userId" element={<SubscriberOverview />} />
                            {userContext.isAdmin && (
                                <>
                                    <Route path="subscribers" element={<AdminSubscribers />} />
                                    <Route path="subscribers/:userId/edit" element={<AdminSubscriberEdit />} />
                                    <Route path="subscribers/new" element={<AdminSubscriberEdit />} />
                                </>
                            )}

                            {/* Sextforce */}

                            <Route path="subscribers/:userId/services/sextforce" element={<SextforceOverview />} />
                            <Route path="subscribers/:userId/services/sextforce/welcome" element={<SextforceWelcome />} />
                            <Route path="subscribers/:userId/services/sextforce/reports" element={<SextforceReports />} />
                            <Route path="subscribers/:userId/services/sextforce/metrics" element={<SextforceMetrics />} />
                            <Route path="subscribers/:userId/services/sextforce/metrics/account" element={<SextforceMetricsAccount />} />
                            <Route
                                path="subscribers/:userId/services/sextforce/metrics/campaigns/*"
                                element={<SextforceMetricsCampaigns />}
                            />
                            <Route path="subscribers/:userId/services/sextforce/metrics/trials/*" element={<SextforceMetricsTrials />} />
                            <Route path="subscribers/:userId/services/sextforce/agents" element={<SextforceManageAgents />} />
                            <Route path="subscribers/:userId/services/sextforce/transactions" element={<SextforceTransactions />} />
                            <Route path="subscribers/:userId/services/sextforce/assign" element={<SextforceAssignTransactions />} />
                            <Route path="subscribers/:userId/services/sextforce/saleproofs" element={<SextforceManageSaleProofs />} />

                            <Route path="subscribers/:userId/services/sextforce/autoRepost" element={<SextforceAutoRepost />} />

                            <Route
                                path="subscribers/:userId/services/sextforce/liveStream/addTippersToList"
                                element={<SextforceLiveStreamAddTippersToList />}
                            />

                            <Route
                                path="subscribers/:userId/services/sextforce/bigBrother/restrictedWords"
                                element={<SextforceBigBrotherRestrictedWords />}
                            />

                            {/* Follow-Back */}

                            <Route path="subscribers/:userId/services/followBack" element={<FollowBackOverview />} />
                            <Route path="subscribers/:userId/services/followBack/welcome" element={<FollowBackWelcome />} />
                            <Route path="subscribers/:userId/services/followBack/settings" element={<FollowBackSettings />} />
                            <Route path="subscribers/:userId/services/followBack/report" element={<FollowBackReport />} />

                            {/* Message Users */}

                            <Route path="subscribers/:userId/services/messageUsers" element={<MessageUsersOverview />} />
                            <Route path="subscribers/:userId/services/messageUsers/welcome" element={<MessageUsersWelcome />} />
                            <Route path="subscribers/:userId/services/messageUsers/sendMessage" element={<MessageUsersSendMessage />} />
                            <Route
                                path="subscribers/:userId/services/messageUsers/autoSendWelcomeMessage"
                                element={<MessageUsersAutoSendWelcomeMessage />}
                            />
                            <Route path="subscribers/:userId/services/messageUsers/history" element={<MessageUsersHistory />} />
                            <Route
                                path="subscribers/:userId/services/messageUsers/autoSendWelcomeMessage/newSubscribers"
                                element={<MessageUsersAutoSendWelcomeMessageNewSubscriber />}
                            />
                            <Route
                                path="subscribers/:userId/services/messageUsers/autoSendWelcomeMessage/returningSubscribers"
                                element={<MessageUsersAutoSendWelcomeMessageReturningSubscriber />}
                            />
                            <Route
                                path="subscribers/:userId/services/messageUsers/autoSendWelcomeMessage/newTrialSubscribers"
                                element={<MessageUsersAutoSendWelcomeMessageNewTrialSubscriber />}
                            />

                            {/* Hostess */}

                            <Route path="subscribers/:userId/services/hostess" element={<HostessOverview />} />
                            <Route path="subscribers/:userId/services/hostess/welcome" element={<HostessWelcome />} />
                            <Route path="subscribers/:userId/services/hostess/autoReply" element={<HostessAutoReplyToFreeloaders />} />
                            <Route path="subscribers/:userId/services/hostess/autoReplyToExpired" element={<HostessAutoReplyToExpired />} />
                            <Route
                                path="subscribers/:userId/services/hostess/autoCampaignTipReply"
                                element={<SextforceAutoCampaignTipReply />}
                            />

                            {/* Labs */}

                            <Route path="subscribers/:userId/labs" element={<LabsOverview />} />
                            <Route path="subscribers/:userId/labs/performerRanking" element={<PerformerRanking />} />

                            {/* Dashboard */}

                            <Route path="*" element={<DashboardHome />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Authenticated;
