import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ShareIcon from '@mui/icons-material/Share';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Skeleton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import SextforceAutoRepostDescription from '../../../components/services/sextforce/autoRepost/SextforceAutoRepostDescription';
import SextforceAutoRepostNewRepost from '../../../components/services/sextforce/autoRepost/SextforceAutoRepostNewRepost';
import SextforceAutoRepostSearchBar from '../../../components/services/sextforce/autoRepost/SextforceAutoRepostSearchBar';
import SextforceAutoRepostTableRepostsHistoryRow from '../../../components/services/sextforce/autoRepost/SextforceAutoRepostTableRepostsHistoryRow';
import useSextforceAutoRepost from '../../../hooks/useSextforceAutoRepost';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceAutoRepost = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const timezone: string = moment.tz.guess();

    const [serviceActive, setServiceActive] = useState<boolean>(false);
    const [sendSuccessNotification, setSendSuccessNotification] = useState<boolean>(false);

    const [query, setQuery] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
    const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());
    const [active, setActive] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<'_id' | 'name' | 'description' | 'nextRepostDate'>('nextRepostDate');
    const {
        data: autoReposts,
        isLoading: autoRepostsLoading,
        refetch: refetchAutoReposts,
    } = useSextforceAutoRepost(query, startDate, endDate, timezone, active, sortBy);
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(false);

    const {
        data: subscriber,
        isLoading: isSubscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettings,
    } = useSubscriber();

    useEffect(() => {
        if (subscriber && subscriber.sextforce) {
            if (subscriber.sextforce.autoRepost) {
                setServiceActive(
                    'active' in subscriber.sextforce.autoRepost && typeof subscriber.sextforce.autoRepost.active === 'boolean'
                        ? subscriber.sextforce.autoRepost.active
                        : true,
                );
                setSendSuccessNotification(
                    'sendSuccessNotification' in subscriber.sextforce.autoRepost &&
                        typeof subscriber.sextforce.autoRepost.sendSuccessNotification === 'boolean'
                        ? subscriber.sextforce.autoRepost.sendSuccessNotification
                        : true,
                );
            } else {
                setServiceActive(true);
                setSendSuccessNotification(true);
            }
        } else {
            setServiceActive(false);
            setSendSuccessNotification(true);
        }
    }, [subscriber]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}`} /> Auto
                        Repost for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto Repost" theme={theme} />
                    </StyledCard>

                    <StyledCard>
                        <SextforceAutoRepostDescription />
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Auto-Repost is currently{' '}
                                {serviceActive ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                {isSubscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={'115px'} height={'51px'} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={serviceActive}
                                        onChange={e => {
                                            setServiceRunning('sextforce.autoRepost', e.target.checked);
                                            setActive(e.target.checked);
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'space-between'}>
                                    <Stack direction={'column'} spacing={1} alignItems="flex-start">
                                        <Typography variant="body1">Send notification on Telegram for successful reposts?</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            If enabled, the system will send a notification to your Telegram account when each Auto Repost
                                            is successful. If you have a lot of Auto Reposts, this can be annoying and you might prefer to
                                            turn these off. It will still send a notification if the Auto Repost fails for any reason.
                                        </Typography>
                                    </Stack>
                                    {isSubscriberLoading || setServiceRunningLoading ? (
                                        <Skeleton width={'60px'} variant="text" />
                                    ) : (
                                        <Switch
                                            checked={sendSuccessNotification}
                                            size="medium"
                                            onChange={e => {
                                                setServiceSettings('sextforce.autoRepost', {
                                                    active: serviceActive,
                                                    sendSuccessNotification: e.target.checked,
                                                });
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </StyledCard>

                    {!showAddNewForm && (
                        <StyledCard noCard>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                onClick={() => {
                                    setShowAddNewForm(true);
                                }}
                            >
                                Add New Auto Repost
                            </Button>
                        </StyledCard>
                    )}

                    {showAddNewForm && (
                        <SextforceAutoRepostNewRepost
                            subscriber={subscriber}
                            refetchAutoReposts={refetchAutoReposts}
                            setShowAddNewForm={setShowAddNewForm}
                            handleCancel={() => {
                                setShowAddNewForm(false);
                            }}
                        />
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar
                            title={`Auto Reposts (${autoReposts ? autoReposts.length.toLocaleString() : 0})`}
                            isLoading={autoRepostsLoading}
                            theme={theme}
                        />
                    </StyledCard>

                    <SextforceAutoRepostSearchBar
                        autoRepostsLoading={autoRepostsLoading}
                        active={active}
                        setActive={setActive}
                        setQuery={setQuery}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        theme={theme}
                    />
                    <StyledCard>
                        <Alert severity="info" icon={false}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    Click on the rows below to see the statistics of each repost. If the repost is not getting enough views,
                                    decrease the frequency of the reposting.
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={0}>
                                        <Typography variant="body2" fontWeight="bold">
                                            Actions
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <MoreTimeIcon fontSize="small" color="success" />
                                            <Typography variant="body2">Change Next Auto Repost Date & Time</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <EditIcon fontSize="small" color="primary" />
                                            <Typography variant="body2">Edit Auto Repost Settings</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <QuickreplyIcon fontSize="small" color="info" />
                                            <Typography variant="body2">
                                                Setup Auto-Reply to Campaign Tip for this post and reposts
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <ShareIcon fontSize="small" color="secondary" />
                                            <Typography variant="body2">Share Auto Repost Updates & Stats</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <DeleteIcon fontSize="small" color="error" />
                                            <Typography variant="body2">Delete Auto Repost</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <PowerSettingsNewIcon fontSize="small" color="success" />
                                            <Typography variant="body2">Reactivate Auto Repost</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Alert>

                        <Box
                            sx={{
                                display: 'block',
                                overflowX: 'auto',
                            }}
                        >
                            {!autoRepostsLoading && autoReposts && autoReposts.length === 0 && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: 'center',
                                        color: theme.palette.text.secondary,
                                        mt: 1,
                                    }}
                                >
                                    No auto reposts yet
                                    <br />
                                    Click on the button above to add a new auto repost
                                </Typography>
                            )}
                            {!autoRepostsLoading && autoReposts && autoReposts.length > 0 && (
                                <>
                                    <Table
                                        size="small"
                                        sx={{
                                            // No padding on the left and right of the table but only on the first and last cell
                                            '& td': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            '& th': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                // Make header cells bold
                                                fontWeight: 'bold',
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={30} align="center">
                                                    <QueryStatsIcon fontSize="small" />
                                                </TableCell>
                                                <TableCell>Post</TableCell>
                                                <TableCell width={72} align="center">
                                                    <Stack direction="column" spacing={0.5}>
                                                        <Typography variant="inherit">Every</Typography>
                                                        <Typography variant="inherit" color="textSecondary">
                                                            Until
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={160} align="center">
                                                    <Stack direction="column" spacing={0.5}>
                                                        <Typography variant="inherit" noWrap>
                                                            Last Repost
                                                        </Typography>
                                                        <Typography variant="inherit" color="textSecondary" noWrap>
                                                            Next Repost
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={70} align="center">
                                                    Reposted
                                                </TableCell>
                                                <TableCell width={125} align="center">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {autoReposts.map(autoRepost => (
                                                <SextforceAutoRepostTableRepostsHistoryRow
                                                    subscriber={subscriber}
                                                    autoRepost={autoRepost}
                                                    refetchAutoReposts={refetchAutoReposts}
                                                    theme={theme}
                                                    key={autoRepost._id}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </Box>
                    </StyledCard>
                </>
            )}
        </Container>
    );
};

export default SextforceAutoRepost;
