import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Alert,
    Box,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect } from 'react';
import { OnlyFansLists } from '../../../../types/onlyFansTypes';

type Props = {
    lists: OnlyFansLists.List[] | void | undefined;
    listsLoading: boolean;
    listId: number | OnlyFansLists.Type | undefined;
    setListId: (listId: number | OnlyFansLists.Type | undefined) => void;
    setListName: (listId: string | undefined) => void;
    refetchLists: () => void;
    session?: any;
    theme: Theme;
};

const MessageUsersFormTargetList = (props: Props) => {
    const { lists, listsLoading, listId, setListId, setListName, refetchLists, session, theme } = props;

    useEffect(() => {
        if (session && session.payload && session.payload.listId && session.payload.listName) {
            setListId(session.payload.listId);
            setListName(session.payload.listName);
        }
    }, [session, setListId, setListName]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Select Target List
                                    </Typography>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Reload Lists from OnlyFans">
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                refetchLists();
                                            }}
                                        >
                                            <RefreshIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                                <Grid item xs={12}>
                                    {listsLoading ? (
                                        <Box textAlign={'center'}>
                                            <Typography variant="caption">
                                                Retrieving your Lists...
                                                <br />
                                                <br />
                                                This might take a while depending on how many Lists you have and OnlyFans servers speed 🤷‍♂️
                                                <br />
                                                <br />
                                                <CircularProgress size={24} />
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            {lists && typeof lists !== 'undefined' ? (
                                                <FormControl fullWidth>
                                                    <InputLabel id="lists">List</InputLabel>
                                                    <Select
                                                        labelId="lists"
                                                        defaultValue={lists.length > 0 && lists[0].id}
                                                        value={listId}
                                                        onChange={e => {
                                                            setListId(e.target.value as number | OnlyFansLists.Type | undefined);
                                                            setListName(
                                                                typeof e.target.value === 'number'
                                                                    ? lists.find(list => list.id === e.target.value)?.name
                                                                    : undefined,
                                                            );
                                                        }}
                                                        fullWidth
                                                        size="medium"
                                                        label="List"
                                                    >
                                                        {lists.map((list: any) => (
                                                            <MenuItem value={list.id} key={list.id}>
                                                                <Grid container spacing={0} sx={{ margin: 0, padding: 0 }}>
                                                                    <Grid item xs>
                                                                        {list.name}
                                                                    </Grid>
                                                                    <Grid item xs="auto" sx={{ paddingRight: 2 }}>
                                                                        <span style={{ color: theme.palette.grey[700] }}>
                                                                            {(list.usersCount as number).toLocaleString()} Users
                                                                        </span>
                                                                    </Grid>
                                                                </Grid>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <Alert severity="error">Could not load lists!</Alert>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                {!listsLoading && (
                                    <Grid item xs={12}>
                                        <Alert severity="info">
                                            Please note that only current fans and expired fans you follow in this list will be able to
                                            receive the message
                                        </Alert>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormTargetList;
