import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoopIcon from '@mui/icons-material/Loop';
import {
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useEffect, useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import { stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersCurrentSessionExclude from '../sendMessage/MessageUsersCurrentSessionExclude';

const SessionRow = (props: {
    row: ScheduledTask;
    timezone: string;
    smallScreen: boolean;
    handleDeleteScheduledMessage: (id: string) => void;
    theme: Theme;
}) => {
    const { row, timezone, smallScreen, handleDeleteScheduledMessage, theme } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                key={row._id}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell padding="none">
                    <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </TableCell>
                <TableCell scope="row">{moment(row.scheduleDateTime).tz(timezone).format('L LT')}</TableCell>
                <TableCell>{targetUsersName(row.payload.usersType, {})}</TableCell>
                {smallScreen && (
                    <TableCell
                        sx={{
                            display: { xs: 'none', sm: 'table-cell' },
                        }}
                    >
                        {stripHtml(row.payload.message)
                            .split('\n')
                            .map((i: string, key: number) => {
                                return <div key={key}>{i}</div>;
                            })}
                    </TableCell>
                )}
                <TableCell align="center">
                    <Tooltip
                        title={
                            row.repeatEveryAmount && row.repeatEveryUnit
                                ? 'This message session will repeat automatically'
                                : 'This message session will not repeat'
                        }
                    >
                        <span>
                            <LoopIcon color={row.repeatEveryAmount && row.repeatEveryUnit ? 'primary' : 'disabled'} />
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        backgroundColor: theme.palette.grey[50],
                    }}
                    colSpan={6}
                >
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        sx={{ maxWidth: { sm: theme.breakpoints.values.sm, md: theme.breakpoints.values.md } }}
                    >
                        {open && (
                            <Grid container spacing={1} sx={{ marginBottom: theme.spacing(0.5) }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Message Details</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Message</strong>
                                </Grid>
                                <Grid item xs={12} sx={{ marginLeft: theme.spacing(2) }}>
                                    {row.payload && 'message' in row.payload && row.payload.message
                                        ? stripHtml(row.payload.message)
                                              .split('\n')
                                              .map((i: string, key: number) => {
                                                  return <div key={key}>{i}</div>;
                                              })
                                        : ''}
                                </Grid>
                                <Grid item xs={6}>
                                    <strong>%USERNAME% Replacement</strong>
                                </Grid>
                                <Grid item xs={6}>
                                    {row.payload.usernameReplacement || <div style={{ color: theme.palette.grey[500] }}>None</div>}
                                </Grid>
                                <Grid item xs={6}>
                                    <strong>To</strong>
                                </Grid>
                                <Grid item xs={6}>
                                    {targetUsersName(row.payload.usersType, {})}
                                </Grid>
                                {row.payload.targetUsers === 'lists' && (
                                    <>
                                        <Grid item xs={4}>
                                            <strong>List Name</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {row.payload && row.payload.listName}
                                        </Grid>
                                    </>
                                )}
                                {row.payload && row.payload.exclude && (
                                    <>
                                        <Grid item xs={12}>
                                            <strong>Excludes</strong>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                                            <MessageUsersCurrentSessionExclude exclude={row.payload.exclude} theme={theme} />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <strong>Attachment</strong>
                                </Grid>
                                <Grid item xs={6}>
                                    {row.payload && row.payload.mediaFiles && row.payload.mediaFiles.length > 0
                                        ? `${row.payload.mediaFiles.length} photo/video`
                                        : 'None'}
                                </Grid>
                                <Grid item xs={6}>
                                    <strong>Repeat Every</strong>
                                </Grid>
                                <Grid item xs={6}>
                                    {row.repeatEveryAmount && row.repeatEveryUnit
                                        ? `${row.repeatEveryAmount} ${row.repeatEveryUnit}`
                                        : 'Does not repeat'}
                                </Grid>
                                {row.repeatEveryAmount && row.repeatEveryUnit && (
                                    <>
                                        <Grid item xs={6}>
                                            <strong>Following Scheduled For</strong>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!row.endDate ||
                                            (row.endDate &&
                                                moment(row.scheduleDateTime)
                                                    .add(row.repeatEveryAmount, row.repeatEveryUnit as moment.DurationInputArg2)
                                                    .isBefore(row.endDate))
                                                ? moment(row.scheduleDateTime)
                                                      .add(row.repeatEveryAmount, row.repeatEveryUnit as moment.DurationInputArg2)
                                                      .tz(timezone)
                                                      .format('L LT')
                                                : 'Will end after this session'}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <strong>Repeat Until</strong>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {moment(row.endDate).tz(timezone).format('L LT')}
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="medium"
                                        startIcon={<DeleteIcon />}
                                        fullWidth={!smallScreen}
                                        onClick={() => {
                                            handleDeleteScheduledMessage(row._id!);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const MessageUsersScheduledList = () => {
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const timezone: string = moment.tz.guess();

    const { data: scheduledMessages, isLoading: scheduledMessagesLoading, deleteScheduledMessage } = useMessageUsersScheduledSessions();

    const [showList, setShowList] = useState<boolean>(false);
    const [list, setList] = useState<ScheduledTask[]>([]);

    useEffect(() => {
        if (scheduledMessagesLoading) {
            return;
        }

        if (scheduledMessages && scheduledMessages.length > 5) {
            setShowList(false);
        } else {
            setShowList(true);
        }
    }, [scheduledMessages, scheduledMessagesLoading]);

    useEffect(() => {
        if (scheduledMessagesLoading) {
            return;
        }

        if (scheduledMessages && scheduledMessages.length > 5) {
            setList(showList ? scheduledMessages : scheduledMessages.slice(0, 5));
        } else {
            setList(scheduledMessages || []);
        }
    }, [scheduledMessages, scheduledMessagesLoading, showList]);

    const handleDeleteScheduledMessage = (id: string) => {
        dialog
            .confirm({
                message: 'Are you sure you want to delete this scheduled message?',
                ok: { text: 'Delete', variant: 'contained', color: 'error' },
                cancel: { text: 'Cancel' },
                title: 'Delete Scheduled Message',
            })
            .then(() => {
                deleteScheduledMessage(id);
            })
            .catch(() => {});
    };

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
                Scheduled Messages ({scheduledMessages ? scheduledMessages.length.toLocaleString() : 0})
            </Typography>
            <Card variant="elevation" sx={{ marginBottom: 4 }}>
                <CardContent>
                    <Grid container flexGrow={1}>
                        {scheduledMessagesLoading ? (
                            <Skeleton />
                        ) : scheduledMessages && scheduledMessages.length === 0 ? (
                            <Typography variant="body1">No messages scheduled</Typography>
                        ) : (
                            <>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    This list shows the currently scheduled messages. Click the row for details and to cancel scheduled
                                    message.
                                </Typography>

                                <Table
                                    size="small"
                                    sx={{
                                        // no left/right padding on the first and last cells
                                        '& td:first-of-type, & th:first-of-type': {
                                            paddingLeft: 0,
                                        },
                                        // no right padding on the last cell
                                        '& td:last-of-type, & th:last-of-type': {
                                            paddingRight: 0,
                                        },
                                        // Bold header
                                        // '& th': {
                                        //     fontWeight: 'bold',
                                        // },
                                        // No wrapping
                                        whiteSpace: 'nowrap',
                                        // make last column sticky
                                        '& th, & td': {
                                            paddingLeft: 0,
                                            paddingRight: 1,

                                            '&:last-child': {
                                                position: 'sticky',
                                                right: 0,
                                                backgroundColor: theme.palette.background.paper,
                                            },
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={20} />
                                            <TableCell sx={{ fontWeight: 'bold', width: { xs: '100px', sm: '150px' } }}>Date</TableCell>
                                            <TableCell width={180} sx={{ fontWeight: 'bold' }}>
                                                Target
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'block' } }}>Message</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold', width: { xs: '24px', sm: '70px' } }}>
                                                <Tooltip title="Scheduled message repeats every interval" placement="top">
                                                    <span>{!smallScreen ? <LoopIcon /> : 'Repeats'}</span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map(scheduledMessage => (
                                            <SessionRow
                                                row={scheduledMessage}
                                                timezone={timezone}
                                                smallScreen={smallScreen}
                                                handleDeleteScheduledMessage={handleDeleteScheduledMessage}
                                                theme={theme}
                                                key={scheduledMessage._id}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                                {scheduledMessages && scheduledMessages.length > 5 && (
                                    <Button variant="text" onClick={() => setShowList(!showList)} sx={{ marginTop: 2 }}>
                                        {showList ? 'Show Less' : 'Show All'}
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MessageUsersScheduledList;
